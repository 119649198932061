import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import useIsInstructor from '../helpers/useIsInstructor';

const BackBreadcrumb = ({ routes, currentRoute }) => {
	const isInstructor = useIsInstructor();
	const history = useHistory();
	return (
		<div style={{ paddingBottom: '0.5em' }}>
			<nav className="breadcrumb" aria-label="breadcrumbs">
				<ul>
					{routes
						.filter((route) => {
							if (isInstructor) {
								return true;
							} else {
								return route.link !== '/students';
							}
						})
						.map((r) => (
							<li key={r.label}>
								<Link to={r.link}>{r.label}</Link>
							</li>
						))}

					<li className="is-active">
						<a href="#">{currentRoute}</a>
					</li>
				</ul>
			</nav>
		</div>
	);
};

export default BackBreadcrumb;
