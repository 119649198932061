import React, { useState, useEffect } from 'react';
import { IoIosCheckmark } from 'react-icons/io';

import * as Colors from '../../color.scss';

import SelfAssessmentDropdown from './SelfAssessmentDropdown';
import CriteriaColumn from './CriteriaColumn';
import CriticalColumn from './CriticalColumn';

const LabTopic = ({
	id,
	name,
	description,
	subTopics,
	studentId,
	labId,
	onSuccessUpdate,
	score,
	totalScore,
	allScored,
}) => {
	const [isShown, setIsShown] = useState(false);
	const toggleIsShown = () => {
		setIsShown(!isShown);
	};

	const calculateNumberOfCriticalRows = (subTopics, index) => {
		let numberOfCriticalRows = 0;
		let currentCriticalId = subTopics[index]?.critical?.id;
		if (!currentCriticalId) {
			return 0;
		}

		if (currentCriticalId === subTopics[index - 1]?.critical?.id) {
			return 0;
		} else {
			numberOfCriticalRows++;
			for (let i = index + 1; i < subTopics.length; i++) {
				if (currentCriticalId === subTopics[i]?.critical?.id) {
					numberOfCriticalRows++;
				} else {
					return numberOfCriticalRows;
				}
			}
		}

		return numberOfCriticalRows;
	};

	const getScored = (subTopics) => {
		let result = 0;
		subTopics.forEach((subTopic) => {
			if (subTopic.critical?.instructorName) {
				result += 1;
			} else {
				let hasInstructorNameInCriteria = false;
				subTopic.criterias.forEach((criteria) => {
					if (criteria.instructorName) {
						hasInstructorNameInCriteria = true;
					}
				});

				if (hasInstructorNameInCriteria) {
					result += 1;
				}
			}
		});

		return result;
	};

	const scored = getScored(subTopics);

	return (
		<>
			<div
				className="columns is-mobile"
				style={{
					backgroundColor: allScored
						? Colors.mainPink
						: Colors.mainBlue,
					padding: '0.3rem',
					color: Colors.white,
					marginTop: '0.3rem',
					cursor: 'pointer',
				}}
				onClick={toggleIsShown}
			>
				<div
					className="column is-9"
					style={{ display: 'flex', alignItems: 'center' }}
				>
					{allScored ? (
						<IoIosCheckmark color={Colors.white} size={30} />
					) : null}
					{name}
				</div>
				<div className="column is-3" style={{ textAlign: 'right' }}>
					{score} / {totalScore}
				</div>
			</div>
			{isShown ? (
				<div className="table-container">
					<table className="table is-fullwidth is-bordered">
						<thead>
							<tr>
								<th style={{ color: Colors.darkBlue }}></th>
								<th style={{ color: Colors.darkBlue }}>GOOD</th>
								<th style={{ color: Colors.darkBlue }}>
									FAIR / UNACCEPTABLE
								</th>
								<th style={{ color: Colors.darkBlue }}>
									CRITICAL ERROR
								</th>
								<th style={{ color: Colors.darkBlue }}>
									Remark
								</th>
								<th style={{ color: Colors.darkBlue }}>
									SELF ASSESSMENT
								</th>
							</tr>
						</thead>
						<tbody>
							{subTopics.map((subTopic, index) => (
								<LabSubTopic
									key={`subtopic-${subTopic.id}`}
									studentId={studentId}
									labId={labId}
									numberOfCriticalRows={calculateNumberOfCriticalRows(
										subTopics,
										index
									)}
									onSuccessUpdate={onSuccessUpdate}
									{...subTopic}
								/>
							))}
						</tbody>
					</table>
				</div>
			) : null}
		</>
	);
};

const LabSubTopic = ({
	id,
	name,
	description,
	criterias,
	critical,
	selfAssessment,
	studentId,
	labId,
	isLabel,
	numberOfCriticalRows,
	onSuccessUpdate,
	remark,
}) => {
	let goodRow = criterias.filter((c) => c.criteriaTypeId === 1)[0];
	let badRow = criterias.filter((c) => c.criteriaTypeId === 2)[0];

	return (
		<tr style={{ color: Colors.mainBlue }}>
			{!isLabel ? (
				<>
					<td style={{ color: Colors.darkBlue }}>{name}</td>
					<CriteriaColumn
						id={goodRow?.id}
						data={goodRow?.name}
						instructor={goodRow?.instructorName}
						updateDate={goodRow?.updateDate}
						subTopicId={id}
						labId={labId}
						studentId={studentId}
						onSuccess={onSuccessUpdate}
					/>
					<CriteriaColumn
						id={badRow?.id}
						data={badRow?.name}
						instructor={badRow?.instructorName}
						updateDate={badRow?.updateDate}
						subTopicId={id}
						labId={labId}
						studentId={studentId}
						onSuccess={onSuccessUpdate}
					/>
				</>
			) : (
				<td
					colSpan={3}
					style={{ color: Colors.darkBlue, textAlign: 'center' }}
				>
					{name}
				</td>
			)}
			{numberOfCriticalRows > 0 ? (
				<td rowSpan={numberOfCriticalRows}>
					<CriticalColumn
						data={critical?.name}
						instructor={critical?.instructorName}
						updateDate={critical?.updateDate}
						subTopicId={id}
						labId={labId}
						studentId={studentId}
						onSuccess={onSuccessUpdate}
					/>
				</td>
			) : null}
			{numberOfCriticalRows > 0 ? (
				<td
					rowSpan={numberOfCriticalRows}
					style={{ color: Colors.mainBlue }}
				>
					{critical.remark}
				</td>
			) : null}
			<td>
				{!isLabel ? (
					<SelfAssessmentDropdown
						value={selfAssessment}
						studentId={studentId}
						labId={labId}
						subTopicId={id}
					/>
				) : null}
			</td>
		</tr>
	);
};

export default LabTopic;
