import ActionName from '../../helpers/ActionName';

const actionName = ActionName('Student');

export const actionTypes = {
	setMonitoringStudent: actionName.get('SET_MONITORING_STUDENT'),
	clearState: actionName.get('CLEAR_STATE'),
	setSelectedGroup: actionName.get('SET_SELECTED_GROUP'),
};

export const setMonitoringStudent = (student) => ({
	type: actionTypes.setMonitoringStudent,
	payload: { student },
});

export const clearState = () => ({
	type: actionTypes.clearState,
});

export const setSelectedGroup = (selectedGroup) => ({
	type: actionTypes.setSelectedGroup,
	payload: { selectedGroup },
});
