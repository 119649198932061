import React, { useState, useEffect } from 'react';

import * as Colors from '../../color.scss';

import { IoMdArrowDropdown } from 'react-icons/io';

export default ({ courses, onToggle }) => {
	const [isActive, setIsActive] = useState('');
	const [activeSemesterYear, setActiveSemesterYear] = useState(
		courses
			? courses[0]?.semesterYear
				? courses[0]?.semesterYear
				: ''
			: ''
	);

	useEffect(() => {
		if (courses && courses[0] && courses[0].semesterYear) {
			setActiveSemesterYear(courses[0].semesterYear);
		}
	}, [courses]);

	const toggleActive = (semesterYear) => {
		//console.log('toggle', isActive);
		setIsActive(isActive ? '' : 'is-active');
		if (semesterYear) {
			setActiveSemesterYear(semesterYear);
		}
	};

	const DropDownContent = ({ course, onToggle }) => (
		<a
			href="#"
			className="dropdown-item"
			style={{ color: Colors.mainBlue }}
			onClick={() => {
				toggleActive(course.semesterYear);
				onToggle(course);
			}}
		>
			{course.semesterYear}
		</a>
	);

	return (
		<div
			style={{
				paddingTop: '0.5rem',
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'space-between',
			}}
		>
			<h1
				className="title is-3"
				style={{
					fontWeight: 'bold',
					color: Colors.mainPink,
					fontFamily: 'leelawadee-bold',
				}}
			>
				All Students
			</h1>
			<div className={`dropdown is-right ${isActive}`}>
				<div className="dropdown-trigger">
					<button
						className="button"
						aria-haspopup="true"
						aria-controls="dropdown-menu"
						onClick={() => toggleActive()}
						style={{
							borderColor: Colors.mainBlue,
							borderWidth: 1,
							borderRadius: 7,
						}}
					>
						<span style={{ color: Colors.mainBlue }}>
							{activeSemesterYear}
						</span>
						<span className="icon is-small">
							<IoMdArrowDropdown
								size={20}
								color={Colors.mainBlue}
							/>
						</span>
					</button>
				</div>
				<div className="dropdown-menu" id="dropdown-menu" role="menu">
					<div className="dropdown-content">
						{courses?.map((course) => (
							<DropDownContent
								key={course.id}
								course={course}
								onToggle={onToggle}
							/>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};
