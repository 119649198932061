import ActionName from '../../helpers/ActionName';

const actionName = ActionName('Modal');

export const actionTypes = {
	alertConfirmModal: actionName.get('ALERT_CONFIRM_MODAL'),
	hideConfirmModal: actionName.get('HIDE_CONFIRM_MODAL'),
};

export const alertConfirmModal = (confirmModal) => ({
	type: actionTypes.alertConfirmModal,
	payload: {
		confirmModal,
	},
});

export const hideConfirmModal = () => ({
	type: actionTypes.hideConfirmModal,
	payload: {},
});
