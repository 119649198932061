import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import HTTP from '../../helpers/HTTP';
import HTTPUtils from '../../helpers/HTTPUtils';
import useIsInstructor from '../../helpers/useIsInstructor';

import * as Colors from '../../color.scss';

import { useHistory } from 'react-router-dom';

const SelfAssessment = ({ value, labId, studentId, subTopicId }) => {
	const [userSelect, setUserSelect] = useState('');
	const history = new useHistory();

	useEffect(() => {
		if (value || value === 0) {
			setUserSelect(value);
		}
	}, [value]);

	const onChange = async (e) => {
		const currentValue = e.target.value;
		setUserSelect(currentValue);
		const request = HTTP(
			'POST',
			`student/${studentId}/selfAssessment`,
			null,
			{
				LaboratoryId: labId,
				SubTopicId: subTopicId,
				SelfAssessment: currentValue,
			}
		);

		const response = await request.execute();
		// if (HTTPUtils.isSuccess(response)) {
		// 	console.log('update success');
		// 	toast.success('Updated!');
		// } else if (HTTPUtils.isUnauthorized(response)) {
		// 	toast.warn('Token expired. Please login again.');
		// 	history.replace('/');
		// } else {
		// 	toast.error('Some error occured');
		// }
		HTTPUtils.checkResponse(response, () => {
			toast.success('Update success');
		});
	};

	const isInstructor = useIsInstructor();

	let instructorLabel = 'Not specified';
	if (userSelect === 0) {
		instructorLabel = 'Cannot';
	} else if (userSelect === 1) {
		instructorLabel = 'Can';
	}

	return (
		<div className="field">
			<div className="control">
				{!isInstructor ? (
					<div className="select" style={{ width: '100%' }}>
						<select
							style={{
								borderColor: Colors.mainBlue,
								color: Colors.mainBlue,
								width: '100%',
							}}
							onChange={onChange}
							value={userSelect}
						>
							<option value="" disabled hidden>
								...
							</option>
							<option value={0}>Cannot</option>
							<option value={1}>Can</option>
						</select>
					</div>
				) : (
					instructorLabel
				)}
			</div>
		</div>
	);
};

export default SelfAssessment;
