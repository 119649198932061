const ActionName = (name) => {
	let page = name;
	const get = (name) => `${page}-${name.toUpperCase()}`;

	return {
		get,
	};
};

export default ActionName;
