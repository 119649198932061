import React, { useEffect, useState } from 'react';
import reduxStore from '../../redux/store';
import { connect } from 'react-redux';

import Navbar from '../../components/Navbar';
import Container from '../../components/Container';
import BackBreadcrumb from '../../components/BackBreadcrumb';
import StudentProfilePanel from '../../components/StudentProfilePanel';

import { MainBackground } from '../../constants/Images';

import HTTP from '../../helpers/HTTP';
import HTTPUtils from '../../helpers/HTTPUtils';

import { updateLoading } from '../../redux/actions/LoadingActions';

import Panel from './Panel';
import AddModal from './AddModal';

const MinusPoint = ({ match, updateLoading }) => {
	const [isShowModal, setIsShowModal] = useState(false);
	const [minusPoints, setMinusPoints] = useState([]);

	const [laboratories, setLaboratories] = useState([]);
	const [minusPointReasons, setMinusPointReasons] = useState([]);

	const { studentId } = match.params;

	const state = reduxStore.getState();
	const student = state.Student.monitoringStudent;

	useEffect(() => {
		initialData();
	}, []);

	useEffect(() => {
		if (student?.id) getLaboratories(student);
	}, [student?.id]);

	const initialData = async () => {
		// console.log(student);
		updateLoading(true);
		await getMinusPoints();
		// await getLaboratories(student);
		await getMinusPointReasons();
		updateLoading(false);
	};

	const getLaboratories = async (student) => {
		// console.log(student);
		const response = await HTTP('GET', `laboratory`, {
			courseId: student?.courseId,
		}).execute();

		HTTPUtils.checkResponse(response, () => {
			// console.log('get all laboratories', response);
			setLaboratories(response.data);
		});
	};

	const getMinusPointReasons = async () => {
		updateLoading(true);
		const response = await HTTP('GET', `minuspoint`, null).execute();

		HTTPUtils.checkResponse(response, () => {
			// console.log('get all minusPoint', response);
			setMinusPointReasons(response.data);
		});
	};

	const getMinusPoints = async () => {
		updateLoading(true);
		const response = await HTTP(
			'GET',
			`student/${studentId}/minusPoint`,
			null
		).execute();

		updateLoading(false);

		HTTPUtils.checkResponse(response, () => {
			// console.log('minusPoint', response);
			setMinusPoints(response.data);
		});
	};

	const forceUpdateTable = () => {
		getMinusPoints();
	};

	return (
		<>
			<Navbar />
			<Container backgroundImage={MainBackground}>
				<div
					style={{
						width: '100%',
						padding: '0.5em',
					}}
				>
					<BackBreadcrumb
						routes={[
							{ label: 'All Students', link: '/students' },
							{
								label: 'Student Detail',
								link: `/students/${studentId}`,
							},
						]}
						currentRoute="Minus point"
					/>
					<StudentProfilePanel studentId={studentId} />
					<Panel
						data={minusPoints}
						setIsShowModal={setIsShowModal}
						studentId={studentId}
						onDeleteSuccess={forceUpdateTable}
					/>
					<AddModal
						isShow={isShowModal}
						laboratories={laboratories}
						minusPointReasons={minusPointReasons}
						setIsShowModal={setIsShowModal}
						updateLoading={updateLoading}
						studentId={studentId}
						forceUpdateTable={forceUpdateTable}
					/>
				</div>
			</Container>
		</>
	);
};

const mapStateToProps = (state) => {
	return {};
};

export default connect(mapStateToProps, { updateLoading })(MinusPoint);
