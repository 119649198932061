import React, { useState } from 'react';
import dayjs from 'dayjs';

import * as Colors from '../../color.scss';

const InstructorLabel = ({ instructor, updateDate }) => {
	let instructorLabel = '';
	if (instructor) instructorLabel = instructor;
	return (
		<div style={{ color: Colors.mainPink, fontWeight: 'bold' }}>
			<span>{instructorLabel}</span>
			{updateDate && instructor ? (
				// <span> - {new Date(updateDate).toLocaleDateString()}</span>
				<span> - {dayjs(updateDate).format('D/M/YY HH:mm')}</span>
			) : null}
		</div>
	);
};

export default InstructorLabel;
