import { useCallback } from 'react';
import Storage from './Storage';
import SecureKey from '../constants/SecureKey';

export default function useIsInstructor() {
	const check = useCallback(() => {
		return Storage.get(SecureKey.loginType) === 'in';
	}, []);
	return check();
}
