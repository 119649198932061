const targetEnvironment = 'production';
const appVersion = '1.1.3';
const rcBuild = 1;
const unauthorizedRetry = 1;

// endpoint config
const serverUrl = {
	local: 'http://localhost:80',
	beta: 'https://navyjone.com',
	production: 'https://oper-api.navyjone.com',
};

const appPrefix = {
	local: '',
	beta: 'operwebdev',
	production: '',
};

const apiUrl = {
	local: serverUrl[targetEnvironment] + '/api/',
	beta: serverUrl[targetEnvironment] + '/operrequirementdevapi/api/',
	production: serverUrl[targetEnvironment] + '/api/',
};

const AppConfig = {
	targetEnvironment: targetEnvironment,
	apiUrl: apiUrl[targetEnvironment],
	displayVersion:
		appVersion +
		'.' +
		rcBuild +
		' ' +
		(targetEnvironment !== 'production'
			? '(' + targetEnvironment + ')'
			: ''),
	storeKeyPrefix: 'opR',
	appPrefix: appPrefix[targetEnvironment],
	unauthorizedRetry: unauthorizedRetry,
};

export default AppConfig;
