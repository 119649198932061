import React from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { FaTrashAlt } from 'react-icons/fa';
import reduxStore from '../../redux/store';

import * as Colors from '../../color.scss';
import {
	alertConfirmModal,
	hideConfirmModal,
} from '../../redux/actions/ModalActions';

import HTTP from '../../helpers/HTTP';
import HTTPUtils from '../../helpers/HTTPUtils';

import useIsInstructor from '../../helpers/useIsInstructor';

export default ({ data, setIsShowModal, onDeleteSuccess, studentId }) => {
	const isInstructor = useIsInstructor();
	const history = useHistory();

	const removeMinusPoint = async (id) => {
		const request = HTTP(
			'POST',
			`student/${studentId}/remove/minusPoint/${id}`,
			null
		);

		const response = await request.execute();
		HTTPUtils.checkResponseV1({
			response,
			history,
			success: () => {
				onDeleteSuccess();
				toast.success(response.data?.data);
			},
		});
	};

	const onTrashClick = (e, id, labName, minusPointName, score) => {
		e.preventDefault();
		if (isInstructor) {
			reduxStore.dispatch(
				alertConfirmModal({
					title: 'Remove Confirmation?',
					message: `Would you exactly sure to remove this minus point? \n- ${labName} \n- ${minusPointName} \n- Point => ${score}`,
					onConfirm: () => removeMinusPoint(id),
					okLabel: 'Remove',
					cancelLabel: 'Cancel',
					mainColor: Colors.mainPink,
					showTextarea: false,
					textareaPlaceholder: '',
				})
			);
		}
	};

	return (
		<div
			className="table-container panel"
			style={{
				padding: '0.5em',
				backgroundColor: 'rgba(255, 255, 255, 0.5)',
			}}
		>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
				}}
			>
				<h1
					className="title is-3"
					style={{ fontWeight: 'bold', color: Colors.mainPink }}
				>
					Minus Point
				</h1>
				{isInstructor ? (
					<button
						className="button panel"
						style={{
							backgroundColor: Colors.mainBlue,
							color: Colors.white,
						}}
						onClick={() => setIsShowModal(true)}
					>
						+ Add
					</button>
				) : null}
			</div>

			<table
				className="table is-narrow is-fullwidth"
				style={{
					backgroundColor: 'rgba(255,255,255,0)',
					color: Colors.mainPink,
				}}
			>
				<thead>
					<tr>
						<th className="main-pink" style={{ width: '10%' }}>
							Date
						</th>
						<th style={{ color: Colors.mainPink, width: '20%' }}>
							Laboratory
						</th>
						<th className="main-pink" style={{ width: '20%' }}>
							Reason
						</th>
						<th className="main-pink" style={{ width: '15%' }}>
							Instuctor
						</th>
						<th className="main-pink" style={{ width: '10%' }}>
							Remark
						</th>
						<th
							style={{
								color: Colors.mainPink,
								width: '10%',
								textAlign: 'center',
							}}
						>
							Point
						</th>
						<th
							style={{
								color: Colors.mainPink,
								width: '5%',
								textAlign: 'center',
							}}
						></th>
					</tr>
				</thead>
				<tbody>
					{data && data.length > 0 ? (
						data.map((row) => (
							<tr key={`${row.id}-minusPoint`}>
								<td>
									{dayjs(row.createDate).format(
										'D MMMM YYYY HH:mm'
									)}
								</td>
								<td>{row.laboratoryName}</td>
								<td>{row.minusPointName}</td>
								<td>{row.instructorName}</td>
								<td>{row.remark}</td>
								<td style={{ textAlign: 'center' }}>
									{row.score === 0 ? 0 : `-${row.score}`}
								</td>
								<td
									style={{
										textAlign: 'center',
										cursor: 'pointer',
									}}
									onClick={(e) =>
										onTrashClick(
											e,
											row.id,
											row.laboratoryName,
											row.minusPointName,
											row.score
										)
									}
								>
									<FaTrashAlt />
								</td>
							</tr>
						))
					) : (
						<tr>
							<td
								style={{
									textAlign: 'center',
									color: Colors.mainPink,
								}}
								colSpan={6}
							>
								No Minus Point
							</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
};
