import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import Container from '../../components/Container';

import { MainBackground, ToothWhite } from '../../constants/Images';

import Navbar from '../../components/Navbar';
import BackBreadcrumb from '../../components/BackBreadcrumb';

import StudentProfilePanel from '../../components/StudentProfilePanel';

import * as Colors from '../../color.scss';

import HTTP from '../../helpers/HTTP';
import HTTPUtils from '../../helpers/HTTPUtils';

import { updateLoading } from '../../redux/actions/LoadingActions';

import ImageBox from './ImageBox';
import { toast } from 'react-toastify';

const StudentDetail = ({ match, updateLoading }) => {
	const [studentLabImage, setStudentLabImage] = useState([]);
	const [
		studentLaboratoryDetailResponse,
		setStudentLaboratoryDetailResponse,
	] = useState(null);
	const history = useHistory();
	const { studentId, labId } = match.params;

	useEffect(() => {
		initPage();
	}, []);

	const initPage = async () => {
		updateLoading(true);
		await getLabDetail();
		await getStudentLabImage();
		updateLoading(false);
	};

	const onUploadSuccess = async () => {
		await getStudentLabImage();
	};

	const getLabDetail = async () => {
		const response = await HTTP(
			'GET',
			`student/${studentId}/laboratory/${labId}`,
			null
		).execute();

		updateLoading(false);
		// if (HTTPUtils.isSuccess(response)) {
		// 	console.log('lab detail', response);
		// 	setStudentLaboratoryDetailResponse(response.data);
		// } else if (HTTPUtils.isUnauthorized(response)) {
		// 	toast.warn('Token expired. Please login again.');
		// 	history.replace('/');
		// } else if (HTTPUtils.isNotFound(response)) {
		// 	toast.warn('Student or Laboratory not found');
		// } else {
		// 	toast.error('Some error occured.');
		// }

		HTTPUtils.checkResponse(response, () => {
			// console.log('lab detail', response);
			setStudentLaboratoryDetailResponse(response.data);
		});
	};

	const getStudentLabImage = async () => {
		updateLoading(true);
		const response = await HTTP(
			'GET',
			`student/${studentId}/laboratory/${labId}/images`,
			null
		).execute();

		updateLoading(false);
		// if (HTTPUtils.isSuccess(response)) {
		// 	console.log('lab images', response);
		// 	setStudentLabImage(response.data);
		// } else if (HTTPUtils.isUnauthorized(response)) {
		// 	toast.warn('Token expired. Please login again.');
		// 	history.replace('/');
		// } else if (HTTPUtils.isNotFound(response)) {
		// 	toast.warn('Student or Laboratory not found');
		// } else {
		// 	toast.error('Some error occured.');
		// }

		HTTPUtils.checkResponse(response, () => {
			// console.log('lab images', response);
			setStudentLabImage(response.data);
		});
	};

	return (
		<>
			<Navbar />
			<Container backgroundImage={MainBackground}>
				<div
					style={{
						width: '100%',
						padding: '0.5em',
					}}
				>
					<BackBreadcrumb
						routes={[
							{ label: 'All Students', link: '/students' },
							{
								label: 'Summary',
								link: `/students/${studentId}`,
							},
						]}
						currentRoute="Lab Image"
					/>
					<StudentProfilePanel studentId={studentId} />
					{studentLaboratoryDetailResponse ? (
						<>
							<h1
								className="title is-4"
								style={{
									fontWeight: 'bold',
									color: Colors.mainPink,
								}}
							>
								{
									studentLaboratoryDetailResponse?.laboratory
										?.name
								}
							</h1>
						</>
					) : null}
					{studentLabImage.map((sli) => (
						<ImageBox
							key={`image-${sli.id}`}
							{...sli}
							studentId={studentId}
							labId={labId}
							onUploadSuccess={onUploadSuccess}
						/>
					))}
				</div>
			</Container>
		</>
	);
};

const mapStateToProps = (state) => {
	return {};
};

export default connect(mapStateToProps, { updateLoading })(StudentDetail);
