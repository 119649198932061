import React, { useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import HTTP from '../../helpers/HTTP';
import HTTPUtils from '../../helpers/HTTPUtils';
import useIsInstructor from '../../helpers/useIsInstructor';

import * as Colors from '../../color.scss';

import InstructorLabel from './InstructorLabel';

import { alertConfirmModal } from '../../redux/actions/ModalActions';
import { useHistory } from 'react-router-dom';

const CriteriaColumn = ({
	id,
	data,
	instructor,
	studentId,
	labId,
	subTopicId,
	alertConfirmModal,
	updateDate,
	onSuccess,
}) => {
	const isInstructor = useIsInstructor();
	const history = useHistory();
	const onClick = async (e) => {
		e.preventDefault();
		if (isInstructor) {
			const request = HTTP(
				'POST',
				`student/${studentId}/submitScore`,
				null,
				{
					LaboratoryId: labId,
					SubTopicId: subTopicId,
					CriteriaId: id,
					Remark: '',
				}
			);

			const response = await request.execute();
			HTTPUtils.checkResponseV1({
				response,
				history,
				success: () => {
					toast.success(instructor ? 'Score Removed' : 'Scored');
					if (onSuccess) onSuccess();
				},
				forbiddenMessage:
					'Submit fail cause Laboratory or topic expired.',
				conflictMessage:
					'This subtopic is already critical. Cannot submit score',
			});
		}
	};

	return (
		<td onClick={onClick}>
			<InstructorLabel instructor={instructor} updateDate={updateDate} />
			<p style={{ whiteSpace: 'break-spaces' }}>{data}</p>
		</td>
	);
};

const mapStateToProps = (state) => {
	return {};
};
export default connect(mapStateToProps, { alertConfirmModal })(CriteriaColumn);
