import React, { useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import HTTP from '../../helpers/HTTP';
import HTTPUtils from '../../helpers/HTTPUtils';
import useIsInstructor from '../../helpers/useIsInstructor';

import * as Colors from '../../color.scss';

import InstructorLabel from './InstructorLabel';

import { alertConfirmModal } from '../../redux/actions/ModalActions';
import { useHistory } from 'react-router-dom';

const CriticalColumn = ({
	data,
	instructor,
	studentId,
	labId,
	subTopicId,
	alertConfirmModal,
	updateDate,
	onSuccess,
}) => {
	const isInstructor = useIsInstructor();
	const history = useHistory();
	const onClick = async (e) => {
		e.preventDefault();
		if (isInstructor) {
			alertConfirmModal({
				title: `Confirm ${instructor ? 'Cancel' : ''} Critical Scoring`,
				mainColor: Colors.mainPink,
				message: data,
				textareaPlaceholder: 'remark more reason',
				showTextarea: instructor ? false : true,
				onConfirm: async (remark) => {
					let url = `student/${studentId}/submitCritical`;
					let toastMessage = 'Critical Added';
					if (instructor) {
						url = `student/${studentId}/cancelCritical`;
						toastMessage = 'Critical Cancelled';
					}
					const request = HTTP('POST', url, null, {
						LaboratoryId: labId,
						SubTopicId: subTopicId,
						Remark: remark,
					});

					const response = await request.execute();

					// if (HTTPUtils.isSuccess(response)) {
					// 	console.log('update success');
					// 	toast.success(toastMessage);
					// 	if (onSuccess) {
					// 		onSuccess();
					// 	}
					// } else if (HTTPUtils.isUnauthorized(response)) {
					// 	toast.warn('Token expired. Please login again.');
					// 	history.replace('/');
					// } else if (HTTPUtils.isForbidden(response)) {
					// 	toast.error(
					// 		'Submit fail cause Laboratory or topic expired.'
					// 	);
					// } else {
					// 	toast.error('Some error occured');
					// }

					HTTPUtils.checkResponse(response, () => {
						// console.log('update success');
						toast.success(toastMessage);
						if (onSuccess) {
							onSuccess();
						}
					});
				},
			});
		}
	};

	return (
		<div onClick={onClick}>
			<InstructorLabel instructor={instructor} updateDate={updateDate} />
			<p style={{ whiteSpace: 'break-spaces' }}>{data}</p>
		</div>
	);
};

export default connect(null, { alertConfirmModal })(CriticalColumn);
