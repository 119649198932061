import ActionName from '../../helpers/ActionName';

const actionName = ActionName('Loading');

export const actionTypes = {
	updateLoading: actionName.get('UPDATE_LOADING'),
};

export const updateLoading = (isLoading) => ({
	type: actionTypes.updateLoading,
	payload: {
		isLoading,
	},
});
