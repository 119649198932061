import ActionName from '../../helpers/ActionName';

const actionName = ActionName('Lab');

export const actionTypes = {
	updateSelectedLab: actionName.get('UPDATE_SELECTED_LAB'),
};

export const updateSelectedLab = (selectedLab) => ({
	type: actionTypes.updateSelectedLab,
	payload: {
		selectedLab,
	},
});
