const Password = (() => {
	const isRightFormat = (input) => {
		// var passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/;
		var passw = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#_+-\/\\\[\]\{\}\(\)\?\$%\^&\*|<>\:\;\,\.\'\"])(?=.{8,})/;
		return input.match(passw);
	};

	return { isRightFormat };
})();

export default Password;
