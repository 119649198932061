import AppConfig from '../constants/AppConfig';
import HTTPUtils from './HTTPUtils';

import SecureKey from '../constants/SecureKey';

const HTTP = (method, service, urlParams, body) => {
	const controller = new AbortController();
	const signal = controller.signal;

	return {
		abort: () => {
			controller.abort();
		},
		execute: async () => {
			return execute(method, service, urlParams, body, 0, signal);
		},
	};
};

export default HTTP;

const execute = async (
	method,
	service,
	urlParams,
	body,
	retryCount,
	abortSignal
) => {
	var data = null;
	var status = null;
	if (!urlParams) {
		urlParams = {};
	}

	if (!retryCount) {
		retryCount = 0;
	}

	try {
		const queryString = HTTPUtils.buildQueryString(urlParams);
		const requestHeaders = await HTTPUtils.getHttpHeader(
			SecureKey.accessToken
		);
		const url = AppConfig.apiUrl + service + queryString;
		//console.log(url, method.toUpperCase());
		const response = await fetch(url, {
			method: method.toUpperCase(),
			headers: requestHeaders,
			signal: abortSignal,
			body: JSON.stringify(body),
		});
		status = response.status;
		try {
			data = await response.json();
		} catch (ex) {}
	} catch (error) {
		console.log(`error in API ${method} : ${service}`);
		console.log(error);
	}

	return HTTPUtils.handleAPIResponse(
		execute,
		{ status, data },
		{ method, service, urlParams, body },
		retryCount,
		abortSignal
	);
};
