import React from 'react';
import { TiTick, TiTimes } from 'react-icons/ti';

import * as Colors from '../color.scss';

const IsCorrect = ({ isCorrect, mandatory }) => {
	if (mandatory) {
		if (isCorrect) return <TiTick color="green" size={'1rem'} />;
		else return <TiTimes color={Colors.mainPink} size={'1rem'} />;
	}

	return null;
};

const RegisterInput = ({
	placeholder,
	onChange,
	value,
	mandatory,
	type,
	isCorrect,
}) => (
	<div
		className="control has-icons-left has-icons-right"
		style={{ textAlign: 'center', marginTop: '2vH' }}
	>
		<input
			className="input is-rounded"
			type={type ? type : 'text'}
			placeholder={`${placeholder}`}
			value={value}
			style={{
				borderWidth: '2px',
				borderColor: Colors.mainBlue,
				textAlign: 'center',
				color: Colors.mainBlue,
				// width: '300px',
			}}
			onChange={(e) => onChange(e.target.value)}
		/>
		{mandatory ? (
			<span className="icon is-left">
				<p
					className="subtitle is-4"
					style={{
						color: 'red',
						marginLeft: '10vw',
						paddingTop: '1vh',
					}}
				>
					*
				</p>
			</span>
		) : null}
		<span className="icon is-right">
			{<IsCorrect isCorrect={isCorrect} mandatory={mandatory} />}
		</span>
	</div>
);

export default RegisterInput;
