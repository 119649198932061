import React, { useState, useEffect } from 'react';

import ImageUploder from '../../components/ImageUploader';
import InstructorConfirmImage from '../../components/InstructorConfirmImage';
import * as Colors from '../../color.scss';

import HTTP from '../../helpers/HTTP';
import HTTPUtils from '../../helpers/HTTPUtils';

import useIsInstructor from '../../helpers/useIsInstructor';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
const LabSummary = ({
	tooth,
	kindOfWork,
	material,
	image,
	labId,
	studentId,
	instructorName,
	onUploadSuccess,
}) => {
	const [inputTooth, setInputTooth] = useState('');
	const [inputKindOfWork, setInputKindOfWork] = useState('');
	const [inputMaterial, setInputMaterial] = useState('');
	const history = new useHistory();

	useEffect(() => {
		if (tooth) {
			setInputTooth(tooth);
		}

		if (kindOfWork) {
			setInputKindOfWork(kindOfWork);
		}

		if (material) {
			setInputMaterial(material);
		}
	}, [tooth, kindOfWork, material]);

	const updateChange = async () => {
		const request = HTTP(
			'POST',
			`student/${studentId}/laboratoryScope`,
			null,
			{
				LaboratoryId: labId,
				Tooth: inputTooth,
				KindOfWork: inputKindOfWork,
				Material: inputMaterial,
				Image: null,
			}
		);

		const response = await request.execute();
		// if (HTTPUtils.isSuccess(response)) {
		// 	console.log('update success');
		// } else if (HTTPUtils.isUnauthorized(response)) {
		// 	toast.warn('Token expired. Please login again.');
		// 	history.replace('/');
		// } else {
		// 	toast.error('Some error occured');
		// }

		HTTPUtils.checkResponse(response, () => {
			toast.success('Update success');
		});
	};

	const isInstructor = useIsInstructor();

	return (
		<div
			className="columns"
			style={{ backgroundColor: Colors.mainPink, padding: '0.5rem' }}
		>
			<div
				className="column is-6"
				style={{ backgroundColor: Colors.white }}
			>
				<ImageUploder
					src={image}
					width={300}
					disabled={isInstructor}
					uploadUrl="image/labscope"
					uploadBody={{
						studentId: studentId,
						laboratoryId: labId,
					}}
					onUploadSuccess={onUploadSuccess}
				/>
				{image ? (
					<InstructorConfirmImage
						postUrl={'image/confirm/labScope'}
						postBody={{
							LaboratoryId: labId,
							StudentId: studentId,
						}}
						instructorName={instructorName}
						onSuccess={onUploadSuccess}
					/>
				) : null}
			</div>
			<div className="column is-6">
				<Row
					label="Tooth"
					value={inputTooth}
					onChange={(e) => setInputTooth(e.target.value)}
					updateChange={updateChange}
				/>
				<Row
					label="Kind of work"
					value={inputKindOfWork}
					onChange={(e) => setInputKindOfWork(e.target.value)}
					updateChange={updateChange}
				/>
				<Row
					label="Material"
					value={inputMaterial}
					onChange={(e) => setInputMaterial(e.target.value)}
					updateChange={updateChange}
				/>
			</div>
		</div>
	);
};

const Row = ({ label, value, updateChange, onChange }) => {
	const isInstructor = useIsInstructor();
	return (
		<div className="columns is-mobile" style={{ marginTop: '0.1rem' }}>
			<div className="column is-5" style={{ color: Colors.white }}>
				{label}
			</div>
			<div className="column is-7">
				<input
					type="text"
					className="input panel"
					style={{
						borderColor: Colors.mainBlue,
						color: Colors.mainBlue,
						width: '100%',
						color: Colors.mainPink,
						backgroundColor: 'white',
						borderRadius: '0.3rem',
					}}
					onChange={onChange}
					onBlur={updateChange}
					value={value}
					placeholder={`${label}`}
					disabled={isInstructor}
				/>
			</div>
		</div>
	);
};

export default LabSummary;
