import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import useIsInstructor from '../helpers/useIsInstructor';
import { updateLoading } from '../redux/actions/LoadingActions';

import * as Colors from '../color.scss';

import HTTP from '../helpers/HTTP';
import HTTPUtils from '../helpers/HTTPUtils';

const InstructorConfirmImage = ({
	updateLoading,
	postUrl,
	postBody,
	instructorName,
	onSuccess,
}) => {
	const isInstructor = useIsInstructor();
	const history = useHistory();

	const confirmImage = async () => {
		updateLoading(true);
		const request = HTTP('POST', postUrl, null, postBody);

		const response = await request.execute();
		updateLoading(false);
		if (HTTPUtils.isSuccess(response)) {
			toast.success('Update Success');
			if (onSuccess) {
				onSuccess();
			}
		} else if (HTTPUtils.isUnauthorized(response)) {
			toast.warn('Token expired. Please login again.');
			history.replace('/');
		} else if (HTTPUtils.isForbidden(response)) {
			toast.error('Submit fail cause expired.');
		} else {
			toast.error('Some error occured');
		}
	};

	const confirmLabel = instructorName ? 'Cancel' : 'Confirm';

	return (
		<div
			style={{
				backgroundColor: 'rgba(255, 255, 255, 0.7)',
				minHeight: 50,
				minWidth: 100,
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			{instructorName ? (
				<span>
					Confirmed by <b>{instructorName}</b>
				</span>
			) : null}

			{isInstructor ? (
				<div style={{ marginTop: 5 }}>
					<button
						className="button is-rounded is-touch"
						style={{
							backgroundColor: instructorName
								? Colors.mainBlue
								: Colors.mainPink,
							color: 'white',
							border: 'none',
							width: '175px',
						}}
						onClick={confirmImage}
					>
						{instructorName ? 'Cancel' : 'Confirm Image'}
					</button>
				</div>
			) : null}
		</div>
	);
};

const mapStateToProps = () => {
	return {};
};

export default connect(mapStateToProps, { updateLoading })(
	InstructorConfirmImage
);
