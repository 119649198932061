import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import Container from '../../components/Container';

import {
	MainBackground,
	ToothWhite,
	PadLockWhite,
} from '../../constants/Images';

import Navbar from '../../components/Navbar';
import BackBreadcrumb from '../../components/BackBreadcrumb';

import StudentProfilePanel from '../../components/StudentProfilePanel';
import SummaryTable from './SummaryTable';

import * as Colors from '../../color.scss';

import HTTP from '../../helpers/HTTP';
import HTTPUtils from '../../helpers/HTTPUtils';

import { updateLoading } from '../../redux/actions/LoadingActions';
import { toast } from 'react-toastify';

const StudentDetail = ({ match, updateLoading }) => {
	const [studentLabSummary, setStudentLabSummary] = useState([]);
	const history = useHistory();
	const { studentId } = match.params;

	useEffect(() => {
		const getStudentLabSummary = async () => {
			updateLoading(true);
			const response = await HTTP(
				'GET',
				`student/${studentId}/labSummary`,
				null
			).execute();

			updateLoading(false);

			HTTPUtils.checkResponseV1({
				response,
				history,
				success: () => {
					setStudentLabSummary(response.data);
				},
				notFoundMessage: 'Student is not found',
			});
		};

		getStudentLabSummary();
	}, []);

	return (
		<>
			<Navbar />
			<Container backgroundImage={MainBackground}>
				<div
					style={{
						width: '100%',
						padding: '0.5em',
					}}
				>
					<BackBreadcrumb
						routes={[{ label: 'All Students', link: '/students' }]}
						currentRoute="Summary"
					/>
					<StudentProfilePanel studentId={studentId} />
					<SummaryTable
						studentId={studentId}
						data={studentLabSummary}
					/>
					<div className="columns is-mobile">
						<div className="column is-1" />
						<div
							className="column is-10"
							style={{ textAlign: 'center' }}
						>
							<Link
								className="button is-rounded is-touch"
								style={{
									backgroundColor: Colors.mainPink,
									color: 'white',
									border: 'none',
									width: '100%',
									maxWidth: 300,
								}}
								to={`/students/${studentId}/checkpoint`}
							>
								<img
									src={ToothWhite}
									width={15}
									style={{
										objectFit: 'contain',
										marginRight: '50px',
									}}
								/>
								Check Point
								<img
									src={ToothWhite}
									width={15}
									style={{
										objectFit: 'contain',
										marginLeft: '50px',
									}}
								/>
							</Link>
						</div>
						<div className="column is-1" />
					</div>
				</div>
			</Container>
		</>
	);
};

const mapStateToProps = (state) => {
	return {};
};

export default connect(mapStateToProps, { updateLoading })(StudentDetail);
