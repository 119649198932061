import React from 'react';
import { connect } from 'react-redux';
import { IoMdCloudUpload } from 'react-icons/io';

import HTTP from '../helpers/HTTP';
import HTTPUtils from '../helpers/HTTPUtils';

import { updateLoading } from '../redux/actions/LoadingActions';

import { toast } from 'react-toastify';

import * as Colors from '../color.scss';

const ImageUploader = ({
	src,
	width,
	height,
	label,
	uploadUrl,
	uploadBody,
	onUploadSuccess,
	onUploadFail,
	updateLoading,
	disabled,
	...rest
}) => {
	const onFileChange = (event) => {
		event.persist();
		let reader = new FileReader();
		let file = event.target.files[0];

		reader.onloadend = () => {
			var base64 = reader.result.substr(reader.result.indexOf(',') + 1);
			var newFile = {
				base64: base64,
				name: file.name,
				size: file.size,
			};

			// console.log(newFile);
			uploadFile(newFile);
		};

		reader.onerror = (error) => {
			console.log('Error: ', error);
		};

		if (file) {
			reader.readAsDataURL(file);
		}
	};

	const uploadFile = async (file) => {
		updateLoading(true);

		const request = await HTTP('POST', uploadUrl, null, {
			...uploadBody,
			...file,
		});
		const response = await request.execute();
		updateLoading(false);
		if (HTTPUtils.isSuccess(response)) {
			toast.success('Upload success');
			if (onUploadSuccess) {
				onUploadSuccess(response.data);
			}
		} else {
			if (response.data?.data) {
				toast.error(response.data.data);
			} else {
				toast.error('Some error occured.');
			}

			if (onUploadFail) {
				onUploadFail();
			}
		}
	};

	return (
		<div style={{ width: '100%', textAlign: 'center' }}>
			<Uploader
				width={width}
				height={height}
				onFileChange={onFileChange}
				disabled={disabled}
				label={label}
			>
				{src ? (
					<img
						src={src}
						width={width}
						height={height}
						style={{ objectFit: 'contain' }}
					/>
				) : null}
			</Uploader>
		</div>
	);
};

const Uploader = ({ onFileChange, disabled, label, children }) => {
	return (
		<div
			className="file is-boxed"
			style={{
				flexDirection: 'column',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<label className="file-label">
				{!disabled ? (
					<input
						type="file"
						name="resume"
						onChange={onFileChange}
						disabled={disabled}
						style={{ display: 'none' }}
						accept="image/*"
					/>
				) : null}
				{children ? (
					children
				) : (
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
							color: Colors.mainBlue,
						}}
					>
						<IoMdCloudUpload size={50} color={Colors.mainBlue} />
						{label ? label : 'Tap to upload'}
					</div>
				)}
				{/* {!disabled ? (
					<span className="file-cta">
						<span className="file-icon">
							<IoMdCloudUpload size={50} />
						</span>
						<span className="file-label">
							{label ? label : 'Upload an image'}
						</span>
					</span>
				) : null} */}
			</label>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {};
};

export default connect(mapStateToProps, { updateLoading })(ImageUploader);
