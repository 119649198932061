import React, { useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import { LoginBackground } from '../../constants/Images';

import Container from '../../components/Container';
import RegisterInput from '../../components/RegisterInput';
import * as Colors from '../../color.scss';

import Password from '../../helpers/Password';

import HTTP from '../../helpers/HTTP';
import HTTPUtils from '../../helpers/HTTPUtils';

import { useHistory } from 'react-router-dom';

const RegisterStudent = ({}) => {
	const [firstname, setFirstname] = useState('');
	const [lastname, setLastname] = useState('');
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [email, setEmail] = useState('');
	const [telephone, setTelephone] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [studentId, setStudentId] = useState('');
	const [group, setGroup] = useState('');
	const [confirmCode, setConfirmCode] = useState('');
	const [number, setNumber] = useState('');

	const history = new useHistory();

	const sigUpPress = async () => {
		//console.log('sign up press');
		const response = await HTTP('POST', 'account/signup/student', null, {
			firstname,
			lastname,
			email,
			telephone,
			username,
			password,
			universityIdNumber: studentId,
			number,
			studentGroupId: group,
			confirmCode,
		}).execute();

		HTTPUtils.checkResponseV1({
			response,
			history,
			success: async () => {
				toast.success('Registered');
				history.replace('/');
			},
		});
	};

	return (
		<Container backgroundImage={LoginBackground}>
			<div
				style={{
					width: '350px',
					backgroundColor: Colors.whiteTransparent,
					margin: '20px',
					marginTop: '25vh',
					borderRadius: '20px',
					paddingBottom: '5vh',
					paddingLeft: '1em',
					paddingRight: '1em',
				}}
			>
				<div
					className="content"
					style={{ marginTop: '2vH', textAlign: 'center' }}
				>
					<h1
						className="title is-1 is-touch"
						style={{ color: Colors.darkBlue }}
					>
						Register
					</h1>
				</div>
				<RegisterInput
					placeholder="firstname"
					mandatory={true}
					onChange={setFirstname}
					value={firstname}
					isCorrect={firstname}
				/>
				<RegisterInput
					placeholder="lastname"
					mandatory={true}
					onChange={setLastname}
					value={lastname}
					isCorrect={lastname}
				/>
				<RegisterInput
					placeholder="email"
					mandatory={true}
					type="email"
					onChange={setEmail}
					value={email}
					isCorrect={email}
				/>
				<RegisterInput
					placeholder="telephone"
					type="telephone"
					onChange={setTelephone}
					value={telephone}
				/>
				<RegisterInput
					placeholder="studentId"
					mandatory={true}
					onChange={setStudentId}
					value={studentId}
					isCorrect={studentId}
				/>
				<RegisterInput
					placeholder="group"
					mandatory={true}
					type="number"
					onChange={setGroup}
					value={group}
					isCorrect={group}
				/>
				<RegisterInput
					placeholder="number"
					mandatory={true}
					type="number"
					onChange={setNumber}
					value={number}
					isCorrect={number}
				/>
				<RegisterInput
					placeholder="username"
					mandatory={true}
					onChange={setUsername}
					value={username}
					isCorrect={username}
				/>
				<RegisterInput
					placeholder="password"
					mandatory={true}
					onChange={setPassword}
					type="password"
					value={password}
					isCorrect={Password.isRightFormat(password)}
				/>
				<p
					className="is-size-7-touch"
					style={{
						// fontSize: 10,
						paddingLeft: 30,
						paddingRight: 10,
						color: Colors.mainPink,
					}}
				>
					Password needs to contain at least 1 uppercase, 1 number, 1
					special character [only !@#$%^&*-_+=] and length over 8
					characters
				</p>
				<RegisterInput
					placeholder="confirm password"
					mandatory={true}
					onChange={setConfirmPassword}
					value={confirmPassword}
					type="password"
					isCorrect={
						confirmPassword === password &&
						Password.isRightFormat(confirmPassword)
					}
				/>
				<RegisterInput
					placeholder="confirmCode"
					mandatory={true}
					onChange={setConfirmCode}
					value={confirmCode}
					isCorrect={confirmCode}
				/>
				<div className="columns is-touch " style={{ marginTop: '5vh' }}>
					<div className="column has-text-centered">
						<button
							className="button is-rounded"
							style={{
								backgroundColor: Colors.mainBlue,
								color: 'white',
								border: 'none',
								width: '300px',
							}}
							onClick={async (e) => {
								await sigUpPress();
							}}
						>
							Sign up
						</button>
					</div>
				</div>
			</div>
		</Container>
	);
};

const mapStateToProps = () => {};

export default connect(mapStateToProps, {})(RegisterStudent);

const styles = {};
