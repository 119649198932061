import React, { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';

import * as Colors from '../../color.scss';
import { connect } from 'react-redux';

import { setSelectedGroup } from '../../redux/actions/StudentActions';

const Tab = ({ group, isActive, onClick }) => {
	return (
		<li className={`${isActive ? 'is-active' : ''}`}>
			<a href="#" onClick={onClick}>
				<span
					style={{ color: Colors.mainPink }}
				>{`Group ${group.studentGroupId}`}</span>
			</a>
		</li>
	);
};

const StudentRow = ({ student, index }) => {
	const history = useHistory();
	return (
		<tr
			onClick={(e) => {
				history.push(`/students/${student.id}`);
			}}
		>
			<td className="main-pink">{index + 1}</td>
			<td className="main-pink">{student.universityIdNumber}</td>
			<td className="main-pink">
				{student.firstname} {student.lastname}
			</td>
		</tr>
	);
};

const Panel = ({ studentGroups, selectedGroup, setSelectedGroup }) => {
	// const [activeGroup, setActiveGroup] = useState(0);

	const onTabClick = (groupIndex) => {
		setSelectedGroup(groupIndex);
	};

	const students = studentGroups[selectedGroup]?.students
		? studentGroups[selectedGroup]?.students
		: [];

	return (
		<div className="panel">
			<div className="tabs is-boxed" style={{ marginBottom: 15 }}>
				<ul>
					{studentGroups?.map((group, index) => (
						<Tab
							key={group.studentGroupId}
							group={group}
							isActive={selectedGroup === index}
							onClick={() => onTabClick(index)}
						/>
					))}
				</ul>
			</div>
			<div className="table-container">
				<table className="table is-narrow is-fullwidth">
					<tbody>
						{students.length > 0 ? (
							students.map((student, index) => (
								<StudentRow
									key={`studentRow${student.id}`}
									index={index}
									student={student}
								/>
							))
						) : (
							<tr>
								<td
									style={{
										textAlign: 'center',
										color: Colors.mainPink,
									}}
								>
									There is no any student in this year.
								</td>
							</tr>
						)}
					</tbody>
				</table>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return { selectedGroup: state.Student.selectedGroup };
};

export default connect(mapStateToProps, { setSelectedGroup })(Panel);
