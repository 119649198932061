import React from 'react';
import { useHistory } from 'react-router-dom';

import { ToothWhite, PadLockWhite } from '../constants/Images';

import useIsInstructor from '../helpers/useIsInstructor';
import Storage from '../helpers/Storage';

import * as Colors from '../color.scss';

export default () => {
	const history = useHistory();
	const isInstructor = useIsInstructor();

	const onLogout = (e) => {
		e.preventDefault();
		Storage.clearAll();
		history.replace(isInstructor ? '/instructor' : '');
	};
	return (
		<nav
			className="navbar"
			role="navigation"
			aria-label="main navigation"
			style={{ backgroundColor: Colors.mainPink }}
		>
			<div className="navbar-brand">
				<div className="navbar-item">
					<img
						src={ToothWhite}
						width={20}
						style={{ objectFit: 'contain' }}
					/>
					<p
						className=""
						style={{
							fontSize: 18,
							fontFamily: 'leelawadee-bold',
							color: 'white',
							marginLeft: 10,
						}}
					>
						Practice II (DRD 412)
					</p>
				</div>
				<div
					className="navbar-burger"
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<div className="navbar-item" onClick={onLogout}>
						<img
							src={PadLockWhite}
							width={15}
							style={{ objectFit: 'contain' }}
						/>
					</div>
				</div>
			</div>
		</nav>
	);
};
