import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Container from '../../components/Container';

import {
	MainBackground,
	ToothWhite,
	PadLockWhite,
} from '../../constants/Images';

import Navbar from '../../components/Navbar';
import * as Colors from '../../color.scss';
import BackBreadcrumb from '../../components/BackBreadcrumb';

import Header from './Header';
import Panel from './Panel';

import HTTP from '../../helpers/HTTP';
import HTTPUtils from '../../helpers/HTTPUtils';

import Storage from '../../helpers/Storage';
import SecureKey from '../../constants/SecureKey';

import { updateLoading } from '../../redux/actions/LoadingActions';
import { toast } from 'react-toastify';

const Students = ({ updateLoading }) => {
	const history = new useHistory();
	const [courses, setCourses] = useState([]);
	const [studentGroups, setStudentGroups] = useState([]);

	useEffect(() => {
		const role = Storage.get(SecureKey.loginType);
		if (role === 'in') {
			initPage();
		} else {
			const studentId = Storage.get(SecureKey.loggedInStudentId);
			history.replace(`/students/${studentId}`);
		}
	}, []);

	const getAllCourses = async () => {
		const response = await HTTP('GET', 'course').execute();

		return HTTPUtils.checkResponseV1({
			response,
			history,
			success: () => {
				return response.data;
			},
			notFoundMessage: 'Course not found',
		});
	};

	const getStudentGroups = async (course) => {
		const response = await HTTP(
			'GET',
			`course/${course.id}/students`
		).execute();

		return HTTPUtils.checkResponseV1({
			response,
			history,
			success: () => {
				return response.data ? response.data : [];
			},
			notFoundMessage: 'Course not found',
		});
	};

	const onChangeSemesterYear = async (course) => {
		updateLoading(true);
		const students = await getStudentGroups(course);
		//console.log('students: ', students);
		setStudentGroups(students);
		updateLoading(false);
	};

	const initPage = async () => {
		updateLoading(true);
		const allCourses = await getAllCourses();
		setCourses(allCourses);
		if (allCourses) {
			const students = await getStudentGroups(allCourses[0]);
			setStudentGroups(students);
		}
		updateLoading(false);
	};

	return (
		<>
			<Navbar />
			<Container backgroundImage={MainBackground}>
				<div
					style={{
						width: '100%',
						padding: '0.5em',
					}}
				>
					<Header
						courses={courses}
						onToggle={onChangeSemesterYear}
					></Header>
					<Panel
						studentGroups={studentGroups}
						history={history}
					></Panel>
				</div>
			</Container>
		</>
	);
};

const mapStateToProps = (state) => {
	return {};
};

export default connect(mapStateToProps, {
	updateLoading,
})(Students);
