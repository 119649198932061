import React from 'react';
import { connect } from 'react-redux';
import Loading from './components/Loading';
import Navigator from './Navigator';
import ConfirmModal from './components/ConfirmModal';
import { ToastContainer } from 'react-toastify';

const AppContainer = ({ isLoading }) => {
	return (
		<>
			<Navigator />
			<ConfirmModal />
			<ToastContainer />
			{isLoading ? <Loading /> : null}
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		isLoading: state.Loading.isLoading,
	};
};

export default connect(mapStateToProps, {})(AppContainer);
