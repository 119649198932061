import React from 'react';

export default ({ children, backgroundImage }) => (
	<div
		className=""
		style={{
			backgroundImage: `url(${backgroundImage})`,
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'top',
			backgroundSize: 'cover, cover',
			backgroundAttachment: 'fixed',
			minHeight: window.innerHeight,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'flex-start',
			padding: 20,
		}}
	>
		{children}
	</div>
);
