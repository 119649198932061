import React, { useState, useEffect } from 'react';
import reduxStore from '../redux/store';
import { useHistory } from 'react-router-dom';

import HTTP from '../helpers/HTTP';
import HTTPUtils from '../helpers/HTTPUtils';
import useIsInstructor from '../helpers/useIsInstructor';

import ImageUploader from '../components/ImageUploader';

import { setMonitoringStudent } from '../redux/actions/StudentActions';

import * as Colors from '../color.scss';

const StudentProfilePanel = ({ studentId }) => {
	const [student, setStudent] = useState({});
	const isInstructor = useIsInstructor();
	const history = useHistory();

	useEffect(() => {
		prepareStudent();
	}, []);

	const prepareStudent = async () => {
		const request = HTTP('GET', `student/${studentId}`, null);

		const response = await request.execute();
		HTTPUtils.checkResponseV1({
			response,
			history,
			success: () => {
				setStudent(response.data);
				reduxStore.dispatch(setMonitoringStudent(response.data));
			},
		});
	};

	return (
		<div
			className="panel"
			style={{
				backgroundColor: Colors.lightBlue,
				padding: '0.5em',
				border: '1px solid',
				borderColor: Colors.darkBlue,
			}}
		>
			<div>
				<table
					className="table is-narrow is-fullwidth"
					style={{
						backgroundColor: 'rgba(255,255,255,0)',
					}}
				>
					<tbody>
						<tr>
							{/* May set max width here */}
							<td style={{ width: '35%' }}>
								<ImageUploader
									src={student.image}
									disabled={isInstructor}
									width={200}
									uploadUrl="image/profile"
									uploadBody={{
										studentId: parseInt(studentId),
									}}
									onUploadSuccess={prepareStudent}
								/>
							</td>
							<td>
								<table
									className="table is-narrow is-fullwidth"
									style={{
										backgroundColor: 'rgba(255,255,255,0)',
									}}
								>
									<tbody>
										<tr>
											{/* May set max width here */}
											<td
												style={
													Styles.studentDetailLabelContainer
												}
											>
												Id:
											</td>
											<td
												style={
													Styles.studentDetailContainer
												}
											>
												{student.universityIdNumber}
											</td>
										</tr>
										<tr>
											<td style={{ textAlign: 'right' }}>
												Name:
											</td>
											<td style={{ textAlign: 'right' }}>
												{student.firstname}{' '}
												{student.lastname}
											</td>
										</tr>
										<tr>
											<td style={{ textAlign: 'right' }}>
												Group:
											</td>
											<td style={{ textAlign: 'right' }}>
												{student.group}
											</td>
										</tr>
										<tr>
											<td style={{ textAlign: 'right' }}>
												Number:
											</td>
											<td style={{ textAlign: 'right' }}>
												{student.number}
											</td>
										</tr>
									</tbody>
								</table>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default StudentProfilePanel;

const Styles = {
	studentDetailLabelContainer: {
		width: '50%',
		textAlign: 'right',
	},
	studentDetailContainer: { width: '50%', textAlign: 'right' },
};
