import { actionTypes } from '../actions/ModalActions';

const initialState = {
	confirmModal: {
		isActive: false,
		title: '',
		message: '',
		onConfirm: () => {},
		okLabel: '',
		cancelLabel: '',
		mainColor: '',
		showTextarea: false,
		textareaPlaceholder: '',
	},
};

export default (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.alertConfirmModal: {
			const { confirmModal } = action.payload;
			// console.log('confirm modal', confirmModal);
			return {
				// ...state,
				confirmModal: {
					...state.confirmModal,
					...confirmModal,
					isActive: true,
				},
			};
		}
		case actionTypes.hideConfirmModal: {
			return {
				// ...state,
				confirmModal: {
					...initialState.confirmModal,
					isActive: false,
				},
			};
		}
		default:
			return state;
	}
};
