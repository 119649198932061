import React, { Component } from 'react';
import ReactLoading from 'react-loading';
import * as Colors from '../color.scss';

const Loading = () => {
	return (
		<div
			style={{
				position: 'fixed',
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				backgroundColor: 'rgba(0, 0, 0, 0.1)',
			}}
		>
			<div
				style={{
					width: '50%',
					height: '25%',
					backgroundColor: 'rgba(0, 0, 0, 0.5)',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					borderRadius: 10,
				}}
			>
				<ReactLoading
					type="spinningBubbles"
					color={Colors.mainPink}
					delay={0}
				/>
			</div>
		</div>
	);
};

export default Loading;
