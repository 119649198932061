import React, { useState, useEffect } from 'react';
import useIsInstructor from '../../../helpers/useIsInstructor';

import * as Colors from '../../../color.scss';

import { FaCheck, FaTimes } from 'react-icons/fa';

export default ({ data, propName, onUpdate, isCheck, isX }) => {
	const [isShow, setIsShow] = useState(data ? data[propName] : false);

	const isInstructor = useIsInstructor();

	useEffect(() => {
		if (data) setIsShow(data[propName]);
	}, [data]);

	const _onClick = (e) => {
		if (
			isInstructor &&
			(propName === 'canNotUse' ||
				(propName !== 'canNotUse' && !data.canNotUse))
		) {
			e.preventDefault();
			const newValue = !isShow;
			setIsShow(newValue);
			onUpdate({ ...data, [propName]: newValue });
		}
	};

	return (
		<td
			style={{
				verticalAlign: 'middle',
				textAlign: 'center',
			}}
			onClick={_onClick}
		>
			{isCheck && isShow ? (
				<FaCheck color={Colors.mainBlue} size={20} />
			) : isX && isShow ? (
				<FaTimes color={Colors.mainPink} size={20} />
			) : null}
		</td>
	);
};
