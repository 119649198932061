import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';

import HTTP from '../../../helpers/HTTP';
import HTTPUtils from '../../../helpers/HTTPUtils';
import useIsInstructor from '../../../helpers/useIsInstructor';

import * as Colors from '../../../color.scss';
import InstructorLabel from '../InstructorLabel';

export default ({
	toothApprovalResponse,
	onUpdateSuccess,
	labId,
	studentId,
}) => {
	const [inputText, setInputText] = useState(
		toothApprovalResponse?.comment ? toothApprovalResponse?.comment : ''
	);
	const [isInitial, setIsInitial] = useState(true);

	const history = useHistory();
	const isInstructor = useIsInstructor();

	useEffect(() => {
		let timeOutId;
		if (!isInitial) {
			timeOutId = setTimeout(() => {
				updateApproval({
					...toothApprovalResponse,
					comment: inputText,
				});
			}, 2000);
		}

		setIsInitial(false);
		return () => clearTimeout(timeOutId);
	}, [inputText]);

	const updateApproval = async (data) => {
		const request = HTTP(
			'POST',
			`student/${studentId}/tooth/approve`,
			null,
			{
				laboratoryId: labId,
				isApproved: data?.isApproved,
				comment: data?.comment,
				approvalId: data?.id,
			}
		);

		const response = await request.execute();
		HTTPUtils.checkResponseV1({
			response,
			history,
			success: () => {
				onUpdateSuccess();
				toast.success(response.data?.data);
			},
		});
	};

	const onApproveClick = (e) => {
		e.preventDefault();
		if (isInstructor) {
			const isApproved = toothApprovalResponse?.isApproved ? null : true;
			updateApproval({ ...toothApprovalResponse, isApproved });
		}
	};

	const onNotApproveClick = (e) => {
		e.preventDefault();
		if (isInstructor) {
			const isApproved =
				toothApprovalResponse?.isApproved !== null &&
				!toothApprovalResponse?.isApproved
					? null
					: false;
			updateApproval({ ...toothApprovalResponse, isApproved });
		}
	};

	const onCommentChange = (e) => {
		e.preventDefault();
		setInputText(e.target.value);
	};

	return (
		<>
			<div
				className="columns table-container"
				style={{ marginTop: '20px' }}
			>
				<table className="table is-fullwidth is-bordered">
					<thead>
						<tr>
							<th
								style={{ color: Colors.darkBlue, width: '30%' }}
							></th>
							<th
								style={{ color: Colors.darkBlue, width: '15%' }}
							>
								Pass
							</th>
							<th
								style={{ color: Colors.darkBlue, width: '15%' }}
							>
								Not Pass
							</th>
							<th
								style={{ color: Colors.darkBlue, width: '40%' }}
							>
								Comment
							</th>
						</tr>
					</thead>
					<tbody>
						<tr style={{ color: Colors.mainBlue }}>
							<td
								style={{
									verticalAlign: 'middle',
									textAlign: 'left',
									color: Colors.darkBlue,
									fontWeight: 'bold',
								}}
							>
								Teeth in block
							</td>
							<td
								onClick={onApproveClick}
								style={{
									verticalAlign: 'middle',
									textAlign: 'center',
								}}
							>
								{toothApprovalResponse &&
								toothApprovalResponse.isApproved ? (
									<InstructorLabel
										instructor={
											toothApprovalResponse?.instructorName
										}
										updateDate={
											toothApprovalResponse?.updateDate
										}
									/>
								) : null}
							</td>
							<td
								onClick={onNotApproveClick}
								style={{
									verticalAlign: 'middle',
									textAlign: 'center',
								}}
							>
								{toothApprovalResponse &&
								toothApprovalResponse.isApproved != null &&
								!toothApprovalResponse.isApproved ? (
									<InstructorLabel
										instructor={
											toothApprovalResponse?.instructorName
										}
										updateDate={
											toothApprovalResponse?.updateDate
										}
									/>
								) : null}
							</td>
							<td>
								<textarea
									className="textarea has-fixed-size"
									placeholder="Add comment here"
									rows={2}
									style={{
										border: 'none',
										color: Colors.mainBlue,
										boxShadow: 'none',
										backgroundColor: 'white',
									}}
									value={inputText}
									disabled={!isInstructor}
									onChange={onCommentChange}
								></textarea>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</>
	);
};
