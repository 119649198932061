import React from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';

import AppConfig from './constants/AppConfig';

import Home from './pages/home';
import loginStudent from './pages/loginStudent';
import RegisterStudent from './pages/registerStudent';
import loginInstructor from './pages/loginInstructor';
import RegisterInstructor from './pages/registerInstructor';
import Students from './pages/students';
import StudentDetail from './pages/studentDetail';
import CheckPoint from './pages/checkPoint';
import MinusPoint from './pages/minusPoint';
import LabDetail from './pages/labDetail';
import LabImage from './pages/labImage';
import ForgotPasswordStudent from './pages/forgotPasswordStudent';
import ForgotPasswordInstructor from './pages/forgotPasswordInstructor';

const PageNotFound = () => <h1>Sorry, Page not found</h1>;

export default () => (
	<div className="main-font">
		<BrowserRouter basename={AppConfig.appPrefix}>
			<Switch className="main-font">
				{/* <Route exact path={`/`} component={Home}/> */}
				<Route exact path={`/`} component={loginStudent} />
				<Route exact path={`/instructor`} component={loginInstructor} />
				<Route exact path={`/register`} component={RegisterStudent} />
				<Route
					exact
					path={`/forgotPassword`}
					component={ForgotPasswordStudent}
				/>
				<Route
					exact
					path={`/forgotPassword/instructor`}
					component={ForgotPasswordInstructor}
				/>
				<Route
					exact
					path={`/register/instructor`}
					component={RegisterInstructor}
				/>
				<Route exact path={`/students`} component={Students} />
				<Route
					exact
					path={`/students/:studentId`}
					component={StudentDetail}
				/>
				<Route
					exact
					path={`/students/:studentId/checkpoint`}
					component={CheckPoint}
				/>
				<Route
					exact
					path={`/students/:studentId/minuspoint`}
					component={MinusPoint}
				/>
				<Route
					exact
					path={`/students/:studentId/labdetail/:labId`}
					component={LabDetail}
				/>
				<Route
					exact
					path={`/students/:studentId/labImage/:labId`}
					component={LabImage}
				/>

				<Route path={`*`} component={PageNotFound} />
			</Switch>
		</BrowserRouter>
	</div>
);
