// import CryptoJS from 'crypto-js';
import React from 'react';
import AppConfig from '../constants/AppConfig';

import SecureKey from '../constants/SecureKey';
import Storage from './Storage';

import HTTPUtils from './HTTPUtils';

import { toast } from 'react-toastify';

export default async function RefreshToken() {
	try {
		const controller = new AbortController();
		const signal = controller.signal;

		const requestHeaders = HTTPUtils.getHttpHeader(SecureKey.refreshToken);

		const loginType =
			Storage.get(SecureKey.loginType) === 'in'
				? 'instructor'
				: 'student';

		const response = await fetch(
			AppConfig.apiUrl + `account/refreshAccess/${loginType}`,
			{
				method: 'PUT',
				headers: requestHeaders,
				signal: signal,
			}
		);

		if (HTTPUtils.isSuccess(response)) {
			const { accessToken, refreshToken } = response.data;
			Storage.set(SecureKey.accessToken, accessToken);
			Storage.set(SecureKey.refreshToken, refreshToken);
		} else {
			Storage.clear(SecureKey.accessToken);
			Storage.clear(SecureKey.refreshToken);
			Storage.clear(SecureKey.loginType);
			toast.warn('Token expired. Please login again.');

			setTimeout(() => window.location.replace('/'), 2000);
		}
	} catch (error) {
		console.log('error in API RefreshToken');
		console.log(error);
		throw error;
	}

	// console.log({ status, statusText, bsRefreshToken });
	return null;
}

// const base64url = (source) => {
// 	// Encode in classical base64
// 	encodedSource = CryptoJS.enc.Base64.stringify(source);

// 	// Remove padding equal characters
// 	encodedSource = encodedSource.replace(/=+$/, '');

// 	// Replace characters according to base64url specifications
// 	encodedSource = encodedSource.replace(/\+/g, '-');
// 	encodedSource = encodedSource.replace(/\//g, '_');

// 	return encodedSource;
// };
