import { actionTypes } from '../actions/StudentActions';

const initialState = {
	monitoringStudent: {},
	selectedGroup: 0,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.setMonitoringStudent: {
			const { student } = action.payload;
			return {
				...state,
				monitoringStudent: student,
			};
		}
		case actionTypes.setSelectedGroup: {
			const { selectedGroup } = action.payload;
			return {
				...state,
				selectedGroup,
			};
		}
		case actionTypes.clearState: {
			return { ...initialState };
		}
		default:
			return state;
	}
};
