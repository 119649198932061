import React, { useState, useEffect } from 'react';

import useIsInstructor from '../../../helpers/useIsInstructor';

import * as Colors from '../../../color.scss';

export default ({ data, onUpdate }) => {
	const [inputText, setInputText] = useState(
		data?.comment ? data?.comment : ''
	);
	const [isInitial, setIsInitial] = useState(true);

	const isInstructor = useIsInstructor();

	useEffect(() => {
		let timeOutId;
		if (!isInitial) {
			timeOutId = setTimeout(() => {
				onUpdate({ ...data, comment: inputText });
			}, 2000);
		}

		setIsInitial(false);
		return () => clearTimeout(timeOutId);
	}, [inputText]);

	const _onChange = (e) => {
		e.preventDefault();
		const value = e.target.value;
		setInputText(value);
	};

	return (
		<td>
			<textarea
				className="textarea has-fixed-size"
				placeholder="Add comment here"
				rows={2}
				style={{
					border: 'none',
					color: Colors.mainBlue,
					boxShadow: 'none',
					backgroundColor: 'white',
				}}
				value={inputText}
				disabled={!isInstructor}
				onChange={_onChange}
			></textarea>
		</td>
	);
};
