import AppConfig from '../constants/AppConfig';

import Storage from './Storage';
import SecureKey from '../constants/SecureKey';

import RefreshToken from './RefreshToken';
import { toast } from 'react-toastify';

const HTTPUtils = (function () {
	const getHttpHeader = async (key) => {
		var requestHeaders = new Headers();
		try {
			const accessToken = Storage.get(key);
			if (accessToken)
				requestHeaders.append('Authorization', 'Bearer ' + accessToken);
		} catch (ex) {
			// console.log('cannot get bsRefreshToken from store');
		}

		requestHeaders.append('Content-Type', 'application/json');
		requestHeaders.append('X-Requested-With', 'XMLHttpRequest');
		return requestHeaders;
	};

	const handleAPIResponse = async (
		baseFunction,
		response,
		request,
		retryCount = 0,
		abortSignal
	) => {
		try {
			// console.log(response);
			if (isSuccess(response)) {
				return response;
			} else if (
				isUnauthorized(response) &&
				retryCount < AppConfig.unauthorizedRetry
			) {
				console.log('unauthroized: ' + retryCount);
				await RefreshToken();
				response = await baseFunction(
					request.method,
					request.service,
					request.urlParams,
					request.body,
					++retryCount,
					abortSignal
				);
			}
		} catch (ex) {
			console.log(ex);
			return {
				status: 500,
			};
		}

		return response;
	};

	const buildQueryString = (urlParams) => {
		let paramString = '';
		if (urlParams) {
			paramString = '?';
			for (var key in urlParams) {
				if (urlParams.hasOwnProperty(key)) {
					paramString =
						paramString + key + '=' + urlParams[key] + '&';
				}
			}

			paramString = paramString.slice(0, -1);
		}

		return paramString;
	};

	const checkResponse = (response, success, fail) => {
		if (isSuccess(response)) {
			success();
		} else if (response.data?.data) {
			toast.error(response.data.data);
			if (fail) fail();
		} else {
			toast.error('Some error occured.');
			if (fail) fail();
		}
	};

	const checkResponseV1 = ({
		response,
		history,
		success,
		badRequest,
		badRequestMessage,
		notFound,
		notFoundMessage,
		conflict,
		conflictMessage,
		forbidden,
		forbiddenMessage,
	}) => {
		if (isSuccess(response)) {
			return success();
		} else if (isUnauthorized(response)) {
			toast.warn('Token expired. Please login again.');
			history.replace('/');
		} else if (badRequestMessage && isBadRequest(response)) {
			toast.error(badRequestMessage);
			if (badRequest) return badRequest();
		} else if (notFoundMessage && isNotFound(response)) {
			toast.error(notFoundMessage);
			if (notFound) return notFound();
		} else if (conflictMessage && isConflict(response)) {
			toast.error(conflictMessage);
			if (conflict) return conflict();
		} else if (forbiddenMessage && isForbidden(response)) {
			toast.error(forbiddenMessage);
			if (forbidden) return forbidden();
		} else if (response.data?.data) {
			toast.error(response.data.data);
		} else {
			toast.error(JSON.stringify(response.data));
		}

		return;
	};

	const isMPaySuccess = (response) => {
		return (
			response.status === 200 &&
			response.data?.status.toUpperCase() === 'S'
		);
	};

	const isSuccess = (response) => {
		return response.status >= 200 && response.status < 300;
	};

	const isUnauthorized = (response) => {
		return response.status === 401;
	};

	const isBadRequest = (response) => {
		return response.status === 400;
	};

	const isInternalServerError = (response) => {
		return response.status === 500;
	};

	const isNotAcceptable = (response) => {
		return response.status === 406 && !response.invalidVersion;
	};

	const isInvalidVersion = (response) => {
		return response.status === 406 && response.invalidVersion;
	};

	const isConflict = (response) => {
		return response.status === 409;
	};

	const isNotFound = (response) => {
		return response.status === 404;
	};

	const isForbidden = (response) => {
		return response.status === 403;
	};

	const isError = (response) => {
		return (
			response.status !== 200 &&
			response.status !== 401 &&
			response.status !== 406
		);
	};

	return {
		getHttpHeader,
		handleAPIResponse,
		isSuccess,
		isMPaySuccess,
		isUnauthorized,
		isError,
		isBadRequest,
		isInternalServerError,
		isInvalidVersion,
		isConflict,
		isNotFound,
		isForbidden,
		isNotAcceptable,
		buildQueryString,
		checkResponse,
		checkResponseV1,
	};
})();

export default HTTPUtils;
