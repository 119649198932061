import React, { useState, useEffect } from 'react';

import * as Colors from '../../../color.scss';

export default ({ data, onUpdate, instructorName }) => {
	const [inputText, setInputText] = useState(
		data?.teethNo ? data.teethNo : ''
	);
	const [isInitial, setIsInitial] = useState(true);

	useEffect(() => {
		let timeOutId;
		if (!isInitial) {
			timeOutId = setTimeout(() => {
				onUpdate({ ...data, teethNo: inputText });
			}, 1500);
		}

		setIsInitial(false);
		return () => clearTimeout(timeOutId);
	}, [inputText]);

	const _onChange = (e) => {
		e.preventDefault();
		const value = e.target.value;
		setInputText(value);
	};

	return (
		<td
			style={{
				verticalAlign: 'middle',
				textAlign: 'center',
			}}
		>
			<input
				className="input"
				type="text"
				placeholder="Tooth no?"
				style={{
					border: 'none',
					width: '100%',
					color: Colors.mainBlue,
					textAlign: 'center',
					backgroundColor: 'white',
					boxShadow: 'none',
				}}
				disabled={instructorName}
				value={inputText}
				onChange={_onChange}
			/>
		</td>
	);
};
