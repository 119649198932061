import React, { useState, useEffect, Text } from 'react';
import { toast } from 'react-toastify';

import * as Colors from '../../color.scss';

import { IoIosRemove } from 'react-icons/io';

import HTTP from '../../helpers/HTTP';
import HTTPUtils from '../../helpers/HTTPUtils';

export default ({
	isShow,
	laboratories,
	minusPointReasons,
	setIsShowModal,
	updateLoading,
	studentId,
	forceUpdateTable,
}) => {
	const [selectedLaboratoryId, setSelectedLaboratoryId] = useState(0);
	const [selectedReasonId, setSelectedReasonId] = useState(0);
	const [remark, setRemark] = useState('');
	const [inputMinusPoint, setInputMinusPoints] = useState(0);

	useEffect(() => {
		if (minusPointReasons && minusPointReasons?.minusPoints?.length > 0)
			setSelectedReasonId(minusPointReasons.minusPoints[0].id);
	}, [minusPointReasons]);

	useEffect(() => {
		if (laboratories && laboratories.length > 0)
			setSelectedLaboratoryId(laboratories[0].id);
	}, [laboratories]);

	const addMinusPoint = async (e) => {
		e.preventDefault();
		updateLoading(true);
		const request = HTTP(
			'POST',
			`student/${studentId}/AddMinusPoint`,
			null,
			{
				MinusPointId: selectedReasonId,
				LaboratoryId: selectedLaboratoryId,
				Score: inputMinusPoint,
				Remark: remark,
			}
		);

		const response = await request.execute();
		updateLoading(false);

		HTTPUtils.checkResponse(response, () => {
			forceUpdateTable();
			closeModal();
			toast.success('Minus Point Added');
		});
	};

	const onRemarkChange = (e) => {
		setRemark(e.target.value);
	};

	const closeModal = () => {
		setSelectedLaboratoryId(laboratories[0].id);
		setSelectedReasonId(minusPointReasons.minusPoints[0].id);
		setRemark('');
		setInputMinusPoints(0);
		setIsShowModal(false);
	};

	return (
		<div className={`modal ${isShow ? 'is-active' : null}`}>
			{/* <div className={`modal is-active`}> */}
			<div className="modal-background" onClick={closeModal}></div>
			<div
				className="modal-card"
				style={{
					paddingLeft: '10vw',
					paddingRight: '10vw',
				}}
			>
				<section
					className="modal-card-body"
					style={{
						borderRadius: 5,
						borderWidth: 1,
						borderColor: Colors.white,
						textAlign: 'center',
					}}
				>
					<h1
						className="title is-5"
						style={{ color: Colors.mainBlue }}
					>
						Add minus point
					</h1>

					<Select
						placeholder="Select Laboratory"
						data={laboratories}
						selectedId={selectedLaboratoryId}
						onChange={setSelectedLaboratoryId}
					/>
					<Select
						placeholder="Select Reason"
						data={minusPointReasons.minusPoints}
						selectedId={selectedReasonId}
						onChange={setSelectedReasonId}
					/>

					<p style={{ color: Colors.mainPink, textAlign: 'left' }}>
						min:0 max:{minusPointReasons?.maxMinusPointPerTime}
					</p>

					<div className="field">
						<p className="control has-icons-left">
							<input
								type="number"
								className="input panel"
								style={{
									borderColor: Colors.mainBlue,
									color: Colors.mainBlue,
									width: '100%',
								}}
								onChange={(e) => {
									const value = e.target.value;
									if (
										value >
										minusPointReasons.maxMinusPointPerTime
									) {
										setInputMinusPoints(
											minusPointReasons.maxMinusPointPerTime
										);
									} else if (value < 0) {
										setInputMinusPoints(0);
									} else {
										setInputMinusPoints(e.target.value);
									}
								}}
								value={inputMinusPoint}
								max={
									minusPointReasons.maxMinusPointPerTime
										? minusPointReasons.maxMinusPointPerTime
										: null
								}
								min={0}
								placeholder={`max: ${minusPointReasons?.maxMinusPointPerTime}`}
							/>
							<span className="icon is-small is-left">
								<IoIosRemove
									color={Colors.mainBlue}
									size={20}
								/>
							</span>
						</p>
					</div>

					<textarea
						className="panel textarea"
						placeholder="remark more reason"
						style={{ borderColor: Colors.mainBlue }}
						onChange={onRemarkChange}
						value={remark}
					></textarea>

					<button
						className="button"
						style={{
							backgroundColor: Colors.mainBlue,
							color: Colors.white,
							width: '100%',
						}}
						onClick={addMinusPoint}
					>
						Add
					</button>
				</section>
			</div>
		</div>
	);
};

const Select = ({ placeholder, data, onChange, selectedId }) => {
	const _onChange = (e) => {
		if (onChange(e.target.value));
	};

	// console.log('data: ', data);
	return (
		<div className="field">
			<div className="control">
				<div className="select" style={{ width: '100%' }}>
					<select
						style={{
							borderColor: Colors.mainBlue,
							color: Colors.mainBlue,
							width: '100%',
						}}
						onChange={_onChange}
						value={selectedId}
					>
						{placeholder ? (
							<option value="" disabled hidden>
								{placeholder}
							</option>
						) : null}
						{data &&
							data.map((row) => (
								<option key={`select-${row.id}`} value={row.id}>
									{row.name}
								</option>
							))}
					</select>
				</div>
			</div>
		</div>
	);
};
