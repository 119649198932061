import { actionTypes } from '../actions/LabActions';

const initialState = {
	selectedLab: null,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.updateSelectedLab: {
			const { selectedLab } = action.payload;
			return {
				...state,
				selectedLab,
			};
		}
		default:
			return state;
	}
};
