import React from 'react';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import { Link, useHistory } from 'react-router-dom';
import { ToothPink, ToothFull } from '../../constants/Images';

import * as Colors from '../../color.scss';

const SummaryTable = ({ data, studentId }) => {
	const history = useHistory();
	const onClickMinusPoint = () => {
		history.push(`/students/${studentId}/minuspoint`);
	};

	const goToLabDetail = (id) => {
		history.push(`/students/${studentId}/labdetail/${id}`);
	};

	return (
		<div
			className="table-container panel"
			style={{
				padding: '0.5em',
				backgroundColor: 'rgba(255, 255, 255, 0.5)',
			}}
		>
			<h1
				className="title is-3"
				style={{ fontWeight: 'bold', color: Colors.mainPink }}
			>
				Summary
			</h1>
			<table
				className="table is-narrow is-fullwidth"
				style={{
					backgroundColor: 'rgba(255,255,255,0)',
					color: Colors.mainPink,
				}}
			>
				<thead>
					<tr>
						<th className="main-pink" style={{ width: '5%' }}>
							No.
						</th>
						<th
							style={{
								color: Colors.mainPink,
								width: '45%',
							}}
						>
							Name
						</th>
						<th
							style={{
								color: Colors.mainPink,
								width: '5%',
								textAlign: 'center',
							}}
						></th>
						<th
							style={{
								color: Colors.mainPink,
								width: '10%',
								textAlign: 'center',
							}}
						>
							Picture
						</th>
						<th
							style={{
								color: Colors.mainPink,
								width: '10%',
								textAlign: 'right',
							}}
						>
							Point
						</th>
						<th
							style={{
								color: Colors.mainPink,
								width: '2%',
								textAlign: 'center',
							}}
						>
							/
						</th>
						<th
							style={{
								color: Colors.mainPink,
								width: '10%',
								textAlign: 'left',
							}}
						>
							Total
						</th>
					</tr>
				</thead>
				<tbody>
					{data?.laboratoriesSummary?.map((row, index) => {
						const laboratory = row.laboratoryResponse;
						return (
							<tr
								key={`summary-${laboratory.id}-${laboratory.name}`}
								style={{ cursor: 'pointer' }}
							>
								<td>{index + 1}</td>
								<td
									onClick={() => goToLabDetail(laboratory.id)}
								>
									<span style={{ color: Colors.mainPink }}>
										{laboratory.name}
									</span>
								</td>
								<td
									style={{
										textAlign: 'center',
									}}
								>
									{row.isLabDone ? (
										<IoIosCheckmarkCircle
											color={Colors.mainPink}
											size={20}
										/>
									) : null}
								</td>
								<td style={{ textAlign: 'center' }}>
									{laboratory.hasLabImage ? (
										<Link
											to={`/students/${studentId}/labImage/${laboratory.id}`}
										>
											<img
												src={
													laboratory.hasLabImage === 2
														? ToothFull
														: ToothPink
												}
												width={15}
												style={{
													objectFit: 'contain',
												}}
											/>
										</Link>
									) : null}
								</td>
								<td style={{ textAlign: 'right' }}>
									{row.score}
								</td>
								<td style={{ textAlign: 'center' }}>/</td>
								<td>{laboratory.totalScore}</td>
							</tr>
						);
					})}
					<tr style={{ fontWeight: 'bold' }}>
						<td />
						<td colSpan={2}>Total Score</td>
						<td />
						<td style={{ textAlign: 'right' }}>
							{data.summaryScore}
						</td>
						<td style={{ textAlign: 'right' }}>/</td>
						<td>{data.totalScore}</td>
					</tr>
					<tr
						style={{ fontWeight: 'bold', cursor: 'pointer' }}
						onClick={onClickMinusPoint}
					>
						<td />
						<td colSpan={2}>Minus point</td>
						<td />
						<td style={{ textAlign: 'right' }}>
							{data.totalMinusPoint}
						</td>
						<td></td>
						<td></td>
					</tr>
					<tr style={{ fontWeight: 'bold' }}>
						<td />
						<td colSpan={2}>Net score</td>
						<td />
						<td style={{ textAlign: 'right' }}>{data.netScore}</td>
						<td style={{ textAlign: 'right' }}>/</td>
						<td>{data.totalScore}</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
};

export default SummaryTable;
