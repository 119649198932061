import React from 'react';

import ImageUploader from '../../components/ImageUploader';
import InstructorConfirmImage from '../../components/InstructorConfirmImage';

import useIsInstructor from '../../helpers/useIsInstructor';
import * as Colors from '../../color.scss';

const ImageBox = ({
	id,
	name,
	studentLaboratoryImages,
	studentId,
	labId,
	onUploadSuccess,
}) => {
	const isInstructor = useIsInstructor();
	const mainImage = studentLaboratoryImages[0];
	return (
		<div
			className="box"
			style={{ textAlign: 'center', color: Colors.mainPink }}
		>
			<b>{name}</b>
			<div
				className="box"
				style={{
					position: 'relative',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					marginTop: 10,
					minHeight: 200,
				}}
			>
				<ImageUploader
					src={mainImage?.image}
					disabled={isInstructor}
					uploadUrl="image/labImage"
					uploadBody={{
						studentId: parseInt(studentId),
						laboratoryId: parseInt(labId),
						laboratoryImageId: parseInt(id),
					}}
					onUploadSuccess={onUploadSuccess}
				/>
			</div>
			{mainImage?.image ? (
				<InstructorConfirmImage
					postUrl={'image/confirm/labImage'}
					postBody={{
						LaboratoryId: labId,
						StudentLaboratoryImageId: mainImage.id,
					}}
					instructorName={mainImage.instructorName}
					onSuccess={onUploadSuccess}
				/>
			) : null}
		</div>
	);
};

export default ImageBox;
