import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Navbar from '../../components/Navbar';
import Container from '../../components/Container';
import BackBreadcrumb from '../../components/BackBreadcrumb';
import StudentProfilePanel from '../../components/StudentProfilePanel';

import { MainBackground } from '../../constants/Images';

import HTTP from '../../helpers/HTTP';
import HTTPUtils from '../../helpers/HTTPUtils';

import { updateLoading } from '../../redux/actions/LoadingActions';

import CheckPointTable from './CheckPointTable';

import * as Colors from '../../color.scss';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

const CheckPoint = ({ match, updateLoading }) => {
	const { studentId } = match.params;
	const [checkpoints, setCheckpoints] = useState([]);
	const [isDoneAll, setIsDoneAll] = useState(false);

	const history = new useHistory();

	useEffect(() => {
		initPage();
	}, []);

	const initPage = async () => {
		updateLoading(true);
		await getCheckpoints();
		await getIsDoneAll();
		updateLoading(false);
	};

	const getCheckpoints = async () => {
		const response = await HTTP(
			'GET',
			`student/${studentId}/experience`,
			null
		).execute();
		// if (HTTPUtils.isSuccess(response)) {
		// 	console.log('expereince', response);
		// 	setCheckpoints(response.data);
		// } else if (response.data?.data) {
		// 	toast.error(response.data.data);
		// } else {
		// 	toast.error('Some error occured.');
		// }

		HTTPUtils.checkResponse(response, () => {
			//console.log('expereince', response);
			setCheckpoints(response.data);
		});
	};

	const getIsDoneAll = async () => {
		const response = await HTTP(
			'GET',
			`student/${studentId}/isDoneAll`,
			null
		).execute();
		// if (HTTPUtils.isSuccess(response)) {
		// 	console.log('isDoneAll', response);
		// 	setIsDoneAll(response.data);
		// } else if (response.data?.data) {
		// 	toast.error(response.data.data);
		// } else {
		// 	toast.error('Some error occured.');
		// }

		HTTPUtils.checkResponse(response, () => {
			// console.log('isDoneAll', response);
			setIsDoneAll(response.data);
		});
	};

	return (
		<>
			<Navbar />
			<Container backgroundImage={MainBackground}>
				<div
					style={{
						width: '100%',
						padding: '0.5em',
					}}
				>
					<BackBreadcrumb
						routes={[
							{ label: 'All Students', link: '/students' },
							{
								label: 'Student Detail',
								link: `/students/${studentId}`,
							},
						]}
						currentRoute="CheckPoint"
					/>
					<StudentProfilePanel studentId={studentId} />
					<CheckPointTable data={checkpoints} isDoneAll={isDoneAll} />
				</div>
			</Container>
		</>
	);
};

const mapStateToProps = (state) => {
	return {};
};

export default connect(mapStateToProps, { updateLoading })(CheckPoint);
