import React from 'react';

import * as Colors from '../color.scss';

const LoginInput = ({ placeholder, onChange, value, icon, type }) => (
	<div
		style={{
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		}}
	>
		<div
			className="control has-icons-left has-icons-right"
			style={{ marginTop: '2vH' }}
		>
			<input
				className="input is-rounded"
				type={type ? type : 'text'}
				placeholder={placeholder}
				value={value}
				style={{
					borderWidth: '2px',
					borderColor: Colors.mainBlue,
					textAlign: 'center',
					color: Colors.mainBlue,
					width: '350px',
				}}
				onChange={(e) => onChange(e.target.value)}
			/>
			<span className="icon is-left">{icon}</span>
		</div>
	</div>
);

export default LoginInput;
