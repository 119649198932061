import React from 'react';

import * as Colors from '../../color.scss';

import { ToothPink } from '../../constants/Images';

export default ({ data, isDoneAll }) => (
	<div
		className="table-container panel"
		style={{
			padding: '0.5em',
			backgroundColor: 'rgba(255, 255, 255, 0.5)',
		}}
	>
		<h1
			className="title is-3"
			style={{ fontWeight: 'bold', color: Colors.mainPink }}
		>
			Check Point
		</h1>
		<table
			className="table is-narrow is-fullwidth panel"
			style={{
				backgroundColor: 'rgba(255,255,255,0)',
				color: Colors.mainPink,
			}}
		>
			<thead>
				<tr>
					<th className="main-pink" style={{ width: '10%' }}>
						No.
					</th>
					<th style={{ color: Colors.mainPink, width: '50%' }}>
						Name
					</th>
					<th style={{ width: '30%' }}></th>
					<th
						style={{
							color: Colors.mainPink,
							width: '10%',
							textAlign: 'center',
						}}
					>
						Check
					</th>
				</tr>
			</thead>
			<tbody>
				{data.map((row, index) => (
					<tr key={`${row.id}-${row.name}`}>
						<td>{index + 1}</td>
						<td>{row.name}</td>
						<td></td>
						<td style={{ textAlign: 'center' }}>
							{row.isAchieve ? (
								<img
									src={ToothPink}
									width={15}
									style={{ objectFit: 'contain' }}
								/>
							) : null}
						</td>
					</tr>
				))}
				<tr>
					<td>{data.length + 1}</td>
					<td>All practice checked</td>
					<td></td>
					<td style={{ textAlign: 'center' }}>
						{isDoneAll ? (
							<img
								src={ToothPink}
								width={15}
								style={{ objectFit: 'contain' }}
							/>
						) : null}
					</td>
				</tr>
			</tbody>
		</table>
	</div>
);
