import React, { useState } from 'react';
import { connect } from 'react-redux';

import { hideConfirmModal } from '../redux/actions/ModalActions';

const ConfirmModal = ({
	isActive,
	title,
	message,
	onConfirm,
	okLabel,
	cancelLabel,
	hideConfirmModal,
	mainColor,
	showTextarea,
	textareaPlaceholder,
}) => {
	const [remark, setRemark] = useState('');

	const _closeModal = () => {
		// console.log('to close modal')
		setRemark('');
		hideConfirmModal();
	};

	const _onConfirm = () => {
		if (onConfirm) {
			onConfirm(remark);
		}

		setRemark('');
		hideConfirmModal();
	};

	const onRemarkChange = (e) => {
		setRemark(e.target.value);
	};

	return (
		<div className={'modal ' + (isActive ? 'is-active' : '')}>
			<div className="modal-background" onClick={_closeModal} />
			<div
				className="modal-content"
				style={{
					backgroundColor: 'white',
					width: '90%',
					padding: '1em',
					borderRadius: '0.5em',
				}}
			>
				<header>
					<h1 className="title is-5" style={{ color: mainColor }}>
						{title}
					</h1>
				</header>
				<div style={{ marginTop: '1em', color: mainColor }}>
					<p style={{ whiteSpace: 'break-spaces' }}>{message}</p>
					{showTextarea ? (
						<textarea
							className="textarea"
							placeholder={textareaPlaceholder}
							style={{
								borderColor: mainColor,
								marginTop: '1em',
								color: mainColor,
							}}
							onChange={onRemarkChange}
						></textarea>
					) : null}
				</div>
				<footer style={{ textAlign: 'right', marginTop: '2em' }}>
					<button
						className="button"
						onClick={_onConfirm}
						style={{
							backgroundColor: mainColor,
							color: 'white',
							width: 100,
						}}
					>
						{okLabel ? okLabel : 'OK'}
					</button>
					<span> </span>
					<button
						className="button is-outlined"
						onClick={_closeModal}
						style={{
							color: mainColor,
							borderColor: mainColor,
							borderWidth: 1,
							width: 100,
						}}
					>
						{cancelLabel ? cancelLabel : 'Cancel'}
					</button>
				</footer>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		isActive: state.Modal.confirmModal.isActive,
		title: state.Modal.confirmModal.title,
		message: state.Modal.confirmModal.message,
		onConfirm: state.Modal.confirmModal.onConfirm,
		okLabel: state.Modal.confirmModal.okLabel,
		cancelLabel: state.Modal.confirmModal.cancelLabel,
		mainColor: state.Modal.confirmModal.mainColor,
		showTextarea: state.Modal.confirmModal.showTextarea,
		textareaPlaceholder: state.Modal.confirmModal.textareaPlaceholder,
	};
};

export default connect(mapStateToProps, { hideConfirmModal })(ConfirmModal);
