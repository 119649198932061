import React, { useState, useEffect, useReducer } from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';

import * as Colors from '../../../color.scss';
import { FaPlus, FaTrashAlt } from 'react-icons/fa';
import useIsInstructor from '../../../helpers/useIsInstructor';
import HTTP from '../../../helpers/HTTP';
import HTTPUtils from '../../../helpers/HTTPUtils';

import ToothColumn from './ToothColumn';
import CheckColumn from './CheckColumn';
import CommentColumn from './CommentColumn';
import InstructorLabel from '../InstructorLabel';

export default ({
	toothConfirmResponse,
	studentId,
	labId,
	onUpdateSuccess,
}) => {
	const [toothData, setToothData] = useState(null);
	const [toothRows, setTootRows] = useState([]);

	const history = useHistory();
	const isInstructor = useIsInstructor();

	useEffect(() => {
		if (toothConfirmResponse) {
			setToothData(toothConfirmResponse);
			convertToRow(toothConfirmResponse);
		}
	}, [toothConfirmResponse]);

	const addToothRow = async () => {
		const request = HTTP(
			'POST',
			`student/${studentId}/laboratory/${labId}/tooth/confirm/create`,
			null
		);

		const response = await request.execute();
		HTTPUtils.checkResponseV1({
			response,
			history,
			success: () => {
				onUpdateSuccess();
				toast.success(response.data?.data);
			},
		});
	};

	const convertToRow = (data) => {
		const dataToConvert = data ? data : toothData;
		let rows = [];
		if (dataToConvert) dataToConvert.forEach((d) => rows.push(row(d)));
		setTootRows(rows);
	};

	const updateData = async (data) => {
		let request;
		if (isInstructor) {
			request = HTTP(
				'POST',
				`student/${studentId}/tooth/confirm/instructor/update`,
				null,
				{
					teethNo: data?.teethNo,
					laboratoryId: labId,
					canUse: data?.canUse,
					canNotUse: data?.canNotUse,
					useInLab: data?.useInLab,
					useInControl: data?.useInControl,
					comment: data?.comment,
					confirmId: data?.id,
				}
			);
		} else {
			request = HTTP(
				'POST',
				`student/${studentId}/tooth/confirm/student/update`,
				null,
				{
					teethNo: data?.teethNo,
					laboratoryId: labId,
					confirmId: data?.id,
				}
			);
		}

		const response = await request.execute();
		HTTPUtils.checkResponseV1({
			response,
			history,
			success: () => {
				onUpdateSuccess();
				toast.success(response.data?.data);
			},
		});
	};

	const removeRow = async (data) => {
		const request = HTTP(
			'POST',
			`student/${studentId}/tooth/confirm/remove`,
			null,
			{
				laboratoryId: labId,
				confirmId: data?.id,
			}
		);

		const response = await request.execute();
		HTTPUtils.checkResponseV1({
			response,
			history,
			success: () => {
				onUpdateSuccess();
				toast.success(response.data?.data);
			},
		});
	};

	const row = (response) => {
		const data = response?.confirm;
		return (
			<tr
				key={`tooth-row-${data?.id}`}
				style={{ color: Colors.mainBlue }}
			>
				<ToothColumn
					data={data}
					onUpdate={updateData}
					instructorName={response?.instructorName}
				/>
				<CheckColumn
					data={data}
					onUpdate={updateData}
					propName="canNotUse"
					isX={true}
				/>
				<CheckColumn
					data={data}
					onUpdate={updateData}
					propName="canUse"
					isCheck={true}
				/>
				<CheckColumn
					data={data}
					onUpdate={updateData}
					propName="useInControl"
					isCheck={true}
				/>
				<CheckColumn
					data={data}
					onUpdate={updateData}
					propName="useInLab"
					isCheck={true}
				/>
				<td
					style={{
						verticalAlign: 'middle',
						textAlign: 'center',
					}}
				>
					<InstructorLabel
						instructor={response?.instructorName}
						updateDate={response?.confirm?.updateDate}
					/>
				</td>
				<CommentColumn data={data} onUpdate={updateData} />
				<td
					style={{
						verticalAlign: 'middle',
						textAlign: 'center',
					}}
					onClick={(e) => {
						e.preventDefault();
						removeRow(data);
					}}
				>
					<div style={{ cursor: 'pointer' }}>
						<FaTrashAlt color={Colors.mainPink} />
					</div>
				</td>
			</tr>
		);
	};

	return (
		<>
			<div
				className="columns table-container"
				style={{ marginTop: '20px' }}
			>
				<table className="table is-fullwidth is-bordered">
					<thead>
						<tr>
							<th
								style={{ color: Colors.darkBlue, width: '15%' }}
							>
								Tooth no.
							</th>
							<th style={{ color: Colors.darkBlue, width: '8%' }}>
								Unusable
							</th>
							<th style={{ color: Colors.darkBlue, width: '8%' }}>
								Usable
							</th>
							<th style={{ color: Colors.darkBlue, width: '8%' }}>
								Lab.
							</th>
							<th style={{ color: Colors.darkBlue, width: '8%' }}>
								Control
							</th>
							<th
								style={{ color: Colors.darkBlue, width: '14%' }}
							>
								Sign
							</th>
							<th
								style={{ color: Colors.darkBlue, width: '30%' }}
							>
								Comment
							</th>
							<th
								style={{ color: Colors.darkBlue, width: '5%' }}
							></th>
						</tr>
					</thead>
					<tbody>
						{toothRows && toothRows.length > 0 ? toothRows : null}
					</tbody>
				</table>
			</div>
			<button
				className="columns button"
				style={{
					color: 'white',
					backgroundColor: Colors.mainPink,
					marginTop: '10px',
				}}
				onClick={(e) => {
					e.preventDefault();
					addToothRow();
				}}
			>
				<span className="icon">
					{/* <i class="fab fa-twitter"></i> */}
					<FaPlus />
				</span>
				<span>Add tooth no.</span>
			</button>
		</>
	);
};
