import { actionTypes } from '../actions/LoadingActions';

const initialState = {
	isLoading: false,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.updateLoading: {
			const { isLoading } = action.payload;
			return {
				...state,
				isLoading,
			};
		}
		default:
			return state;
	}
};
