import React from 'react';
import { connect } from 'react-redux';

import './App.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';

import { Provider } from 'react-redux';
import store from './redux/store';
import './Bulma.scss';
import './styles.scss';
import './font.scss';
import AppContainer from './AppContainer';

const App = () => (
	<Provider store={store}>
		<AppContainer />
	</Provider>
);

export default App;
