import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import HTTP from '../../helpers/HTTP';
import { LoginBackground } from '../../constants/Images';
import { TiUserOutline, TiThSmall } from 'react-icons/ti';
import { toast } from 'react-toastify';

import * as Colors from '../../color.scss';

import { useHistory, Link } from 'react-router-dom';

import HTTPUtils from '../../helpers/HTTPUtils';

import Storage from '../../helpers/Storage';
import SecureKey from '../../constants/SecureKey';

import LoginInput from '../../components/LoginInput';
import { updateLoading } from '../../redux/actions/LoadingActions';
import { setSelectedGroup } from '../../redux/actions/StudentActions';

const Login = ({ updateLoading, setSelectedGroup }) => {
	const [usernameInput, setUsernameInput] = useState('');
	const [passwordInput, setPasswordInput] = useState('');
	const [isEnterPressed, setIsEnterPressed] = useState(false);

	let history = useHistory();
	useEffect(() => {
		updateLoading(false);
		setSelectedGroup(0);

		const listener = (event) => {
			if (event.code === 'Enter' || event.code === 'NumpadEnter') {
				event.preventDefault();
				setIsEnterPressed(true);
			}
		};

		window.addEventListener('keydown', listener);
		return () => window.removeEventListener('keydown', listener);
	}, []);

	useEffect(() => {
		if (isEnterPressed) loginPress();
		setIsEnterPressed(false);

		return;
	}, [isEnterPressed]);

	const loginPress = async () => {
		if (!usernameInput) toast.error('Please fill a username.');
		else if (!passwordInput) toast.error('Please fill a password');
		else {
			updateLoading(true);
			const response = await HTTP(
				'POST',
				'account/login/instructor',
				null,
				{
					username: usernameInput,
					password: passwordInput,
				}
			).execute();
			updateLoading(false);
			// console.log('response', response);

			HTTPUtils.checkResponseV1({
				response,
				history,
				success: async () => {
					const { accessToken, refreshToken } = response.data;
					Storage.set(SecureKey.accessToken, accessToken);
					Storage.set(SecureKey.refreshToken, refreshToken);
					Storage.set(SecureKey.loginType, 'in'); // TODO: More secure to keep it in redux
					history.replace(`/students`);
				},
			});
		}

		setPasswordInput('');
	};

	return (
		<>
			<div
				className="is-touch"
				style={{
					backgroundImage: `url(${LoginBackground})`,
					backgroundRepeat: 'no-repeat',
					backgroundPosition: 'center',
					backgroundSize: 'cover, cover',
					width: window.innerWidth,
					minHeight: window.innerHeight,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					flexDirection: 'column',
				}}
			>
				<h1
					className="title is-1 is-touch"
					style={{ color: Colors.darkBlue }}
				>
					Login
				</h1>

				<LoginInput
					label="Username"
					placeholder="username"
					onChange={setUsernameInput}
					value={usernameInput}
					icon={
						<TiUserOutline
							color={Colors.mainBlue}
							size={'1.5rem'}
						/>
					}
				/>

				<LoginInput
					label="Password"
					placeholder="password"
					type="password"
					onChange={setPasswordInput}
					value={passwordInput}
					icon={<TiThSmall color={Colors.mainBlue} size={'1rem'} />}
				/>
				<div style={{ marginTop: '2vH' }}></div>
				<div className="columns is-touch ">
					<div className="column has-text-centered">
						<button
							className="button is-rounded is-touch"
							style={{
								backgroundColor: Colors.mainPink,
								color: 'white',
								border: 'none',
								width: '175px',
							}}
							onClick={async (e) => {
								await loginPress();
							}}
						>
							Log in Instructor
						</button>
						<div style={{ marginTop: '3em' }}>
							<Link
								to={`/forgotPassword/instructor`}
								style={{
									color: Colors.lightBlue,
									textDecorationLine: 'underline',
								}}
							>
								Forgot Password
							</Link>
						</div>
					</div>
				</div>
				<div style={{ marginTop: '2vH' }}></div>
				<div className="columns is-touch ">
					<div className="column has-text-centered">
						<button
							className="button is-rounded"
							style={{
								backgroundColor: Colors.mainBlue,
								color: 'white',
								border: 'none',
								width: '300px',
							}}
							onClick={() => history.push('/register/instructor')}
						>
							Sign up Instructor
						</button>
					</div>
				</div>
			</div>
			<div style={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}>
				<p
					className="subtitle is-4"
					style={{ color: Colors.mainPink, textAlign: 'center' }}
				>
					Requirement for Operative Dentistry Practice II (DRD 412)
				</p>
			</div>
		</>
	);
};

const mapStateToProps = () => {
	return {};
};

export default connect(mapStateToProps, { updateLoading, setSelectedGroup })(
	Login
);
