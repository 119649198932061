import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Container from '../../components/Container';

import {
	MainBackground,
	ToothWhite,
	PadLockWhite,
} from '../../constants/Images';

import Navbar from '../../components/Navbar';
import BackBreadcrumb from '../../components/BackBreadcrumb';

import StudentProfilePanel from '../../components/StudentProfilePanel';

import LabSummary from './LabSummary';
import LabTopic from './LabTopic';

import * as Colors from '../../color.scss';

import HTTP from '../../helpers/HTTP';
import HTTPUtils from '../../helpers/HTTPUtils';

import { updateLoading } from '../../redux/actions/LoadingActions';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import ToothConfirm from './ToothConfirmTable/ToothConfirm';
import ToothApproval from './ToothApprovalTable/ToothApproval';

const LabDetail = ({ match, updateLoading }) => {
	const [
		studentLaboratoryDetailResponse,
		setStudentLaboratoryDetailResponse,
	] = useState(null);

	const [toothConfirmResponse, setToothConfirmResponse] = useState(null);
	const [toothApprovalResponse, setToothApprovalResponse] = useState(null);

	const history = new useHistory();
	const { studentId, labId } = match.params;
	useEffect(() => {
		getLabDetail();
	}, []);

	const isLabTooth = (laboratory) => {
		return laboratory && laboratory.hasToothConfirm;
	};

	const getLabDetail = async () => {
		// updateLoading(true);

		const response = await HTTP(
			'GET',
			`student/${studentId}/laboratory/${labId}`,
			null
		).execute();

		HTTPUtils.checkResponseV1({
			response,
			history,
			success: async () => {
				setStudentLaboratoryDetailResponse(response.data);

				if (isLabTooth(response?.data?.laboratory)) {
					await getToothConfirm();
					await getToothApproval();
				}

				// updateLoading(false);
			},
		});
	};

	const getToothConfirm = async () => {
		const response = await HTTP(
			'GET',
			`student/${studentId}/laboratory/${labId}/tooth/confirm`,
			null
		).execute();

		// console.log(response.data);
		HTTPUtils.checkResponseV1({
			response,
			history,
			success: () => {
				setToothConfirmResponse(response.data);
			},
		});
	};

	const getToothApproval = async () => {
		const response = await HTTP(
			'GET',
			`student/${studentId}/laboratory/${labId}/tooth/approval`,
			null
		).execute();

		HTTPUtils.checkResponseV1({
			response,
			history,
			success: () => {
				setToothApprovalResponse(response.data);
			},
		});
	};

	return (
		<>
			<Navbar />
			<Container backgroundImage={MainBackground}>
				<div
					style={{
						width: '100%',
						padding: '0.5em',
					}}
				>
					<BackBreadcrumb
						routes={[
							{ label: 'All Students', link: '/students' },
							{
								label: 'Summary',
								link: `/students/${studentId}`,
							},
						]}
						currentRoute={
							studentLaboratoryDetailResponse?.laboratory?.name
						}
					/>
					<StudentProfilePanel studentId={studentId} />
					{studentLaboratoryDetailResponse ? (
						<>
							<h1
								className="title is-4"
								style={{
									fontWeight: 'bold',
									color: Colors.mainPink,
								}}
							>
								{
									studentLaboratoryDetailResponse?.laboratory
										?.name
								}
							</h1>
							<LabSummary
								{...studentLaboratoryDetailResponse}
								onUploadSuccess={getLabDetail}
								studentId={studentId}
								labId={labId}
							/>
							{isLabTooth(
								studentLaboratoryDetailResponse?.laboratory
							) ? (
								<ToothConfirm
									studentId={studentId}
									labId={labId}
									toothConfirmResponse={toothConfirmResponse}
									onUpdateSuccess={getToothConfirm}
								/>
							) : null}

							{isLabTooth(
								studentLaboratoryDetailResponse?.laboratory
							) && toothApprovalResponse ? (
								<ToothApproval
									labId={labId}
									studentId={studentId}
									toothApprovalResponse={
										toothApprovalResponse
									}
									onUpdateSuccess={getToothApproval}
								/>
							) : null}
							{studentLaboratoryDetailResponse.topics.map(
								(topic) => (
									<LabTopic
										{...topic}
										key={`topic-${topic.id}`}
										studentId={studentId}
										labId={labId}
										onSuccessUpdate={getLabDetail}
									/>
								)
							)}
						</>
					) : null}
				</div>
			</Container>
		</>
	);
};

const mapStateToProps = (state) => {
	return {};
};

export default connect(mapStateToProps, { updateLoading })(LabDetail);
